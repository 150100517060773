.search-input-404 #ajaxsearchpro1_1 input{
    color: #aaa !important;
    font-size: 20px !important; 
    padding-top: 0px !important;
}
.search-input-404 .proinput{
    padding: 0px !important;
}
.search-input-404 ::-webkit-input-placeholder { 
    color: #aaa !important;
    font-size: 20px !important;
 }
.search-input-404 ::-moz-placeholder { 
    color: #aaa !important;
    font-size: 20px !important;
 }
.search-input-404 :-ms-input-placeholder {
    color: #aaa !important;
    font-size: 20px !important;
 }
.search-input-404 :-moz-placeholder { 
    color: #aaa !important;
    font-size: 20px !important;
 }

 .liste-entreprises .block-search-keyword .de-scontrol .keyword-search,.liste-produits .block-search-keyword .de-scontrol .keyword-search{
    border-color: hsl(0, 0%, 80%) !important;
    border-radius: 0px !important;
    border-style: solid !important;
    border-width: 1px !important;
    min-height: 38px !important;
    margin: 0px !important;
 }

@media only screen and (max-width: 700px) {
    .block-search-all #search-places {
        max-width: 100%;
        border: 0 !important;
        padding-right: 0px;
    }
}
.alice-carousel .__inactive{
    opacity: 0!important;
}
/* ::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
    background: #888; 
}
::-webkit-scrollbar-thumb:hover {
    background: #555; 
} */