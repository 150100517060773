.content-input-tel{
    width: 100%;
    margin-bottom: 10px;
    height: 50px;
  }
  .content-input-tel .prefixe{
    width: 44px;
    height: 41px;
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
    border: solid 1px #aaa;
    border-right: none;
  }
  .content-input-tel .input-tel{
    width: calc(100% - 44px);
    display: block;
    float: left;
  }
   .star{
   color: #e11a18;
   }
   .stepul {
   display: table;
   width: 40%;
   list-style: none;
   margin: 10px auto 40px;
   padding: 0;
   overflow: initial;
   }
   .stepul li {
   display: table-cell;
   width: 20%;
   text-align: center;
   }
   .stepul li a {
   position: relative;
   display: block;
   height: auto;
   font-size: 14px;
   font-weight: normal;
   color: #ccc;
   padding: 0;
   text-transform: none;
   text-decoration: none;
   cursor: default;
   }
   .stepul li a .img {
   display: inline-block;
   font-size: 18px;
   color: #ccc;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 50px;
   padding: 7px 15px 9px;
   margin: 0;
   }
   .stepul li .text {
   display: block;
   }
   .stepul li.active a .img {
   color: #e11a18;
   background-color: #fff;
   border-color: #e11a18;
   }
   .stepul li.active .text {
   color: #e11a18;
   }

   body {
   background-color: #f1f1f1;
   }
   #regForm {
   background-color: #ffffff;
   padding-top: 50px;
   min-width: 300px;
   }
   h1 {
   text-align: center;  
   }
   input,.input-logo {
     padding: 8px;
     width: 100%;
     font-size: 17px;
     border: 1px solid #aaaaaa;
    }
    .input-logo {
     
      height: 150px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
    #input-logo{
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; 
    }
    .input-logo #logo{
      opacity: 0;
      z-index: -99999999;
      width: 0px;
      height: 0px;
      position: absolute;
    }
   input.invalid {
   background-color: #ffdddd;
   }
   select.invalid {
   background-color: #ffdddd;
   }
   button {
   background-color: #fff;
   color: #e11a18;
   border: 1px solid #e11a18;
   border-radius: 5px;
   padding: 10px 25px;
   font-size: 17px;
   cursor: pointer;
   }
   button:hover {
   opacity: 0.8;
   }
   #prevBtn {
   background-color: #fff;
   color: #bbb;
   border: 1px solid #bbb;
   }
   .select2{
   border: 1px solid #aaaaaa;
   
   height: 40px;
   width: 100%;
   color: #444;
   font-size: 13px;
   }
   .select2>div{
       border: none;
   }
  .reclamation_envoye{
    text-align: center;
    padding: 10px;
    margin: 10px;
    background: green;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
   }
   .text-name-company{
    line-height: 15px;
    font-size: 12px;
    margin-bottom: 0px;
   }
   .text-name-company strong{
    font-weight: bold !important;
   }
   img.img-b2b-logo {
      max-height: 70px;
      padding: 0px;
  }
  span.btn.btn-b2b-logo {
    font-size: 15px;
    border: solid 1px red;
    border-radius: 50px;
    padding: 0px 10px;
}
p.message-b2b {
text-align: center;
width: 100%;
font-weight: bold;
}
.searched_companies{
    width: 100%;
    border: 1px solid#eee;
    list-style: none;
    padding: 2px;
    font-size: 12px;
    cursor: pointer;
    }
    .searched_companies:hover{
    background-color: #eee;
    }
    .span_destinataire{
    border: 1px solid #ccc;
    padding: 2px 10px;
    display: inline-block;
    margin: 5px 10px;
    }
    .span_destinataire b:hover{
    cursor: pointer;
    }
    .error {
      display: none;
      color: red;
    }
    .chosen-single{
        border:1px solid #aaaaaa
        }
        .chosen-container{
        width: 100%
        }
    @media only screen and (min-width: 900px) {
        #days{
        width: 150px;
        }
        }
        @media only screen and (max-width: 899px) {
        #days{
        width: 72px;
        }
        }
        input[type=checkbox]{
        width:20px;
        }
        #divopen select {
        color: rgba(0, 0, 0, 0.8);
        border-color: rgba(0, 0, 0, 0.3);
        }
        .input-logo img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .rounded-0{
            border-radius: 0px !important;
        }
        .divdayshorairestravail select {
            margin: 0px 5px;
        }
        .divdayshorairestravail {
            margin-bottom: 8px;
        }

.modal-verifie-nom-entreprise>div>div{
  width:80% !important;
  padding-top: 20px !important;
}
.ModaleVerifieNomEntreprise .div-close-modale{
  width: 100%;
  display: flex;
  justify-content: end;
  height: 20px;
  align-items: center;
}
.ModaleVerifieNomEntreprise .div-close-modale .close-modale{
  font-weight: 600;
    font-family: cursive;
    color: red;
    font-size: 18px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    user-select: none;
}
.ModaleVerifieNomEntreprise .content-modale{
  min-height: 120px;
}
.entreprise-valid {
  padding: 10px;
  margin: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  color: white;
  background-color: #37D4A6;
  font-weight: bold;
}
.entreprise-valid p {
  margin-top: 20px;
}
.btn-verifie-existence-false,.btn-verifie-existence-true{
  background: #e11a18;
  padding: 10px;
  font-Size: 13px;
  color: #fff;
  border-Radius: 50px;
}
.btn-verifie-existence-true{
  background: rgb(70, 160, 73);
  border-color: rgb(70, 160, 73);;
}

.btn_entreprise_author{
  width: 100%;
    height: 10%;
    background: #e11a18;
    padding: 10px;
    color: white;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}
.entreprise-valid {
    padding: 10px;
    margin: 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    color: white;
    background-color: #37D4A6;
    font-weight: bold;
}
.entreprise-valid p{
  margin-top: 20px
}
.btn_entreprise_author p {
    color: white;
    text-align: center;
    margin: 0px;
}

.info-form-addentreprise strong {
    font-weight: bold;
}
.info-form-addentreprise {
    margin-top: -20px;
    font-size: 17px;
    font-weight: 600;
    color: red;
}

.reclamation-succes {
  width: 100%;
  background: green;
  color: #fff;
  text-align: center;
  font-size: 20px;
  padding: 20px;
  border-radius: 5px;
}
.lodesubmitform{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000055;
}

.enteprisespropose:hover {
  font-weight: 700;
  cursor: pointer;
}
.enteprisespropose {
  margin-right: 10px;
  display: block;
}