/**
*	Ryan - Resume/CV/vCard Template (HTML)
*	Version: 2.5
*	Author: beshleyua
*	Author URL: http://themeforest.net/user/beshleyua
*	Copyright © Cvio by beshleyua. All Rights Reserved.
**/


/*
		Background Gradient
	*/

body {
    background-color: #fff;
}

.background.gradient {
    background: #fff;
    background: -webkit-linear-gradient(top left, #ffffff 25%, #e5e5e5 100%);
    background: linear-gradient(top bottom right, #ffffff 25%, #e5e5e5 100%);
}


/*
		Primary Color
	*/

.preloader .spinner .double-bounce1,
.preloader .spinner .double-bounce2,
.lnk:hover .arrow:before,
.button:hover .arrow:before,
.lnk:hover .arrow:after,
.button:hover .arrow:after,
.resume-items .resume-item.active .date:before,
.skills-list ul li .progress .percentage,
.single-post-text ul>li:before,
.comment-text ul>li:before,
.content-sidebar .main-menu ul li.page_item_has_children.current_page_item>a:before,
.content-sidebar .main-menu ul li.page_item_has_children.current_page_item>a:after,
.content-sidebar .main-menu ul li.page_item_has_children:hover>a:before,
.content-sidebar .main-menu ul li.page_item_has_children:hover>a:after,
.content-sidebar .main-menu ul li.page_item_has_children.current_page_parent>a:before,
.content-sidebar .main-menu ul li.page_item_has_children.current_page_parent>a:after,
.content-sidebar .main-menu ul li.page_item_has_children.current_page_ancestor>a:before,
.content-sidebar .main-menu ul li.page_item_has_children.current_page_ancestor>a:after,
.content-sidebar .close:hover:before,
.content-sidebar .close:hover:after,
.header-expert .menu-btn:hover span,
.header-expert .menu-btn:hover span:before,
.header-expert .menu-btn:hover span:after,
.info-list ul li strong,
.profile .main-menu ul li.page_item_has_children.current_page_item>a:before,
.profile .main-menu ul li.page_item_has_children.current_page_item>a:after,
.profile .main-menu ul li.page_item_has_children:hover>a:before,
.profile .main-menu ul li.page_item_has_children:hover>a:after,
.profile .main-menu ul li.page_item_has_children.current_page_parent>a:before,
.profile .main-menu ul li.page_item_has_children.current_page_parent>a:after,
.profile .main-menu ul li.page_item_has_children.current_page_ancestor>a:before,
.profile .main-menu ul li.page_item_has_children.current_page_ancestor>a:after,
.service-items .service-item .icon,
.revs-carousel .owl-dot.active,
.custom-content-reveal span.custom-content-close,
.fc-calendar .fc-row>div.fc-today,
.fc-calendar .fc-content:hover span.fc-date,
.fc-calendar .fc-row>div.fc-today span.fc-date,
.skills-list.dotted ul li .progress .percentage .da span,
.preloader .spinner.default-circle:before,
.preloader .spinner.default-circle:after,
.preloader .spinner.clock:before,
.preloader .spinner.box-rotation:after,
.header-expert .cart-btn .cart-icon .cart-count,
.woocommerce span.onsale,
.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover,
.woocommerce ul.products li.product .button.add_to_cart_button.added,
.popup-box .preloader .spinner.default-circle:before,
.popup-box .preloader .spinner.default-circle:after,
.popup-box .preloader-popup .spinner.default-circle:before,
.popup-box .preloader-popup .spinner.default-circle:after,
.single-post-text ul>li:before,
.comment-text ul>li:before,
.blog-content ul>li:before {
    background: #e11a18;
}

.lnk:hover,
.button:hover,
.lnk:hover .ion,
.button:hover .ion,
a,
a:hover,
input:focus,
textarea:focus,
.header-expert .top-menu ul li:hover a,
.header-expert .top-menu ul li.active a,
.header-expert .top-menu ul li.current-menu-item a,
.header-expert .top-menu ul li:hover a .icon,
.header-expert .top-menu ul li.active a .icon,
.header-expert .top-menu ul li:hover a .link,
.header-expert .top-menu ul li.active a .link,
.header-expert .top-menu ul li.current-menu-item a .icon,
.header-expert .top-menu ul li.current-menu-item a .link,
.header-expert .profile .subtitle,
.card-started .profile .subtitle,
.content-sidebar .profile .subtitle,
.card-started .profile .social a:hover .ion,
.card-started .profile .social a:hover .fab,
.card-started .profile .social a:hover .fas,
.content-sidebar .profile .social a:hover .ion,
.content-sidebar .profile .social a:hover .fab,
.content-sidebar .profile .social a:hover .fas,
.pricing-items .pricing-item .icon,
.fuct-items .fuct-item .icon,
.resume-title .icon,
.skill-title .icon,
.resume-items .resume-item.active .date,
.content.works .filter-menu .f_btn.active,
.box-item:hover .desc .name,
.single-post-text p a,
.comment-text p a,
.post-text-bottom span.cat-links a,
.post-text-bottom .tags-links a,
.post-text-bottom .tags-links span,
.page-numbers.current,
.page-links a,
.post-comments .post-comment .desc .name,
.post-comments .post-comment .desc span.comment-reply a:hover,
.content-sidebar .main-menu ul li.current_page_item>a,
.content-sidebar .main-menu ul li:hover>a,
.content-sidebar .main-menu ul li.current_page_parent>a,
.content-sidebar .main-menu ul li.current_page_ancestor>a,
.content-sidebar .widget ul li a:hover,
.content-sidebar .tagcloud a,
.card-started .profile .subtitle,
.content-sidebar .profile .subtitle,
.content-sidebar .profile .typed-cursor,
.card-started .profile .typed-cursor,
.content .title .first-word,
.content .title::first-letter,
.content .title .first-letter::first-letter,
.content-sidebar h2.widget-title .first-word,
.content-sidebar h2.widget-title::first-letter,
.content-sidebar h2.widget-title .first-letter::first-letter,
.box-item .date,
.profile .main-menu ul li.current-menu-item a,
.profile .main-menu ul li.current_page_item>a,
.profile .main-menu ul li:hover>a,
.profile .main-menu ul li.current_page_parent>a,
.profile .main-menu ul li.current_page_ancestor>a,
.custom-header nav span:before,
.fc-calendar .fc-row>div.fc-content:hover:after,
.skills-list.list ul li .name:before,
.preloader .spinner.recursive-circle,
.woocommerce nav.woocommerce-pagination ul li a:focus,
.woocommerce nav.woocommerce-pagination ul li a:hover,
.woocommerce nav.woocommerce-pagination ul li span.current,
.woocommerce .star-rating,
strong.woocommerce-review__author,
.woocommerce-message::before,
.single-post-text p a,
.comment-text p a,
.blog-content p a,
.card-started .profile .social a:hover .ion,
.card-started .profile .social a:hover .fab,
.card-started .profile .social a:hover .fas,
.card-started .profile .social a:hover .fa,
.content-sidebar .profile .social a:hover .ion,
.content-sidebar .profile .social a:hover .fab,
.content-sidebar .profile .social a:hover .fas,
.content-sidebar .profile .social a:hover .fa {
    color: #000;
}

.content .title .first-word,
.content .title:first-letter,
.content-sidebar h2.widget-title .first-word,
.content-sidebar h2.widget-title:first-letter {
    color: #e11a18!important;
}

.card-started .profile .image:before,
.content-sidebar .profile .image:before,
.content .title:before,
.box-item .image .info:before,
.content-sidebar h2.widget-title:before {
    background: -moz-linear-gradient(-45deg, rgba(225, 26, 24, 0.15) 0%, rgba(225, 26, 24, 0.01) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(225, 26, 24, 0.15) 0%, rgba(225, 26, 24, 0.01) 100%);
    background: linear-gradient(135deg, rgba(225, 26, 24, 0.15) 0%, rgba(225, 26, 24, 0.01) 100%);
}

.card-started:after {
    /* background: -moz-linear-gradient(-45deg, rgba(225, 26, 24, 0.4) 0%, rgba(225, 26, 24, 0.01) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(225, 26, 24, 0.4) 0%, rgba(225, 26, 24, 0.01) 100%);
    background: linear-gradient(135deg, rgba(225, 26, 24, 0.4) 0%, rgba(225, 26, 24, 0.01) 100%); */
    display:none;
}

.box-item .image .info:before {
    background: -moz-linear-gradient(-45deg, rgba(225, 26, 24, 0.5) 0%, rgba(225, 26, 24, 0.01) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(225, 26, 24, 0.5) 0%, rgba(225, 26, 24, 0.01) 100%);
    background: linear-gradient(135deg, rgba(225, 26, 24, 0.5) 0%, rgba(225, 26, 24, 0.01) 100%);
}

.card-started .profile .slide,
.content-sidebar .profile .slide {
    background-color: rgba(225, 26, 24, 0.1);
}

.pricing-items .pricing-item .feature-list ul li strong {
    background: rgba(225, 26, 24, 0.15);
}

input:focus,
textarea:focus,
.revs-carousel .owl-dots .owl-dot,
.custom-header,
.post-text-bottom .tags-links a,
.post-text-bottom .tags-links span,
.content-sidebar .tagcloud a,
.resume-items .resume-item.active .date,
.box-item .date,
.content.skills .skills-list.circles .progress .bar,
.content.skills .skills-list.circles .progress .fill,
.preloader .spinner.clock,
.preloader .spinner.box-rotation,
.skills-list.circles .progress .bar,
.skills-list.circles .progress.p51 .fill,
.skills-list.circles .progress.p52 .fill,
.skills-list.circles .progress.p53 .fill,
.skills-list.circles .progress.p54 .fill,
.skills-list.circles .progress.p55 .fill,
.skills-list.circles .progress.p56 .fill,
.skills-list.circles .progress.p57 .fill,
.skills-list.circles .progress.p58 .fill,
.skills-list.circles .progress.p59 .fill,
.skills-list.circles .progress.p60 .fill,
.skills-list.circles .progress.p61 .fill,
.skills-list.circles .progress.p62 .fill,
.skills-list.circles .progress.p63 .fill,
.skills-list.circles .progress.p64 .fill,
.skills-list.circles .progress.p65 .fill,
.skills-list.circles .progress.p66 .fill,
.skills-list.circles .progress.p67 .fill,
.skills-list.circles .progress.p68 .fill,
.skills-list.circles .progress.p69 .fill,
.skills-list.circles .progress.p70 .fill,
.skills-list.circles .progress.p71 .fill,
.skills-list.circles .progress.p72 .fill,
.skills-list.circles .progress.p73 .fill,
.skills-list.circles .progress.p74 .fill,
.skills-list.circles .progress.p75 .fill,
.skills-list.circles .progress.p76 .fill,
.skills-list.circles .progress.p77 .fill,
.skills-list.circles .progress.p78 .fill,
.skills-list.circles .progress.p79 .fill,
.skills-list.circles .progress.p80 .fill,
.skills-list.circles .progress.p81 .fill,
.skills-list.circles .progress.p82 .fill,
.skills-list.circles .progress.p83 .fill,
.skills-list.circles .progress.p84 .fill,
.skills-list.circles .progress.p85 .fill,
.skills-list.circles .progress.p86 .fill,
.skills-list.circles .progress.p87 .fill,
.skills-list.circles .progress.p88 .fill,
.skills-list.circles .progress.p89 .fill,
.skills-list.circles .progress.p90 .fill,
.skills-list.circles .progress.p91 .fill,
.skills-list.circles .progress.p92 .fill,
.skills-list.circles .progress.p93 .fill,
.skills-list.circles .progress.p94 .fill,
.skills-list.circles .progress.p95 .fill,
.skills-list.circles .progress.p96 .fill,
.skills-list.circles .progress.p97 .fill,
.skills-list.circles .progress.p98 .fill,
.skills-list.circles .progress.p99 .fill,
.skills-list.circles .progress.p100 .fill {
    border-color: #e11a18;
}

.preloader .spinner.recursive-circle,
.preloader .spinner.recursive-circle:after {
    border-color: #e11a18;
    border-top-color: transparent;
}

blockquote {
    border-left-color: #e11a18;
}

.preloader .spinner.simple-circle {
    border-right-color: #e11a18;
}

.preloader .spinner.quantum-spinner,
.preloader .spinner.quantum-spinner:before,
.preloader .spinner.quantum-spinner:after {
    border-top-color: #e11a18;
}


/*
		Heading Color
	*/

.content .title {
    color: #323232;
}


/*
		Text Color
	*/

body {
    color: #646464;
}