.borde-block{
    padding: 30px 30px;
    background: #fff;
    border: 1px solid #b8c7c8;
    height: 100%;
}

.youtube_video {
    position: relative;
}

.youtube_video .min-youtube_video {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sue-menu .scroll-nav ul{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sue-menu .scroll-nav ul li{
    position: relative;
}
.sue-menu .scroll-nav ul li .menu-item{
    padding: 20px 20px;
    position: relative;
    font-weight: 700;
    color: #888da0;
    font-size: 13px;
    text-transform: uppercase;
}
.sue-menu .scroll-nav ul li .menu-item.active-item{
    color: red !important;
    margin-top: 5px !important;
    border-top: solid 1px;
    border-bottom: 0;
}

.sue-menu .scroll-nav ul li:before,.sue-menu .scroll-nav ul li:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    height: 20px;
    margin-top: -10px;
    background: #eee;
    width: 1px;
}
.scroll-nav.scroll-init.menu-desk{
    margin-top: 20px;
    margin-bottom: 20px;
}
#menu_mobile{
    display: none;
}
.divqrcode {
    display: flex;
    justify-content: center;
}
.divqrcode iframe{
    width: 100%;
    height: 200px;
    border: none;
}
.essb_links_list.essb_force_hide_name.essb_force_hide{
    list-style: none;
    display: flex;
    justify-content: center;
}
.essb_links_list.essb_force_hide_name.essb_force_hide .essb_item.nolightbox{
    width: 40px;
    height: 40px;
    margin-top: 15px;
    border: solid 2px;
    border-radius: 50%;
    margin-right: 5px;
}
.essb_link_facebook{
    color: #3a579a!important;
    border-color:#3a579a;
}
.essb_link_twitter{
    color: #00abf0!important;
    border-color:#00abf0;
}
.essb_link_linkedin{
    color: #127bb6!important;
    border-color:#127bb6;
}
#bottom_buttons{
    display: none;
}
.list-author-widget-contacts.list-item-widget-contacts li {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
}
.list-author-widget-contacts li span {
    min-width: 40px;
}
.list-author-widget-contacts li span i {
    margin-right: 7px;
    font-size: 14px;
}
.list-author-widget-contacts li a {
    margin-left: 20px;
    color: #878c9f;
}
.list-widget-social, .list-widget-social li {
    float: left;
}
.list-item-widget-contacts ul,.list-widget-social ul{
    list-style: none;
}
.list-widget-social{
    width: 100%;
    margin-bottom: 20px;
}
.list-widget-social li a{
    border: 1px solid #e11a18;
    color: #e11a18;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: block;
    margin-top: -5px;
    margin-left: 10px;
}
.sujet-form-contact{
    width: 100%;
    height: 40px;
    margin-bottom: 5px;
    border-radius: 5px;
}
.place_post_title {
    color: #3c3c3b !important;
}
.grie-b2b {
    color: #7c7c7c !important;
}
.details-list .fa{
    color: #878c9f;
}
.list-widget-social {
    margin-top: 20px;
}
.list-author-widget-contacts li span, .list-author-widget-contacts li a {
    
    text-align: left;
    font-weight: 500;
}

.list-author-widget-contacts li {
    height: 40px;
    width: 100%;
    margin-bottom: 15px;
}
.title-prode-liste{
    width: 100%;
    font-size: 14px;
    text-align: center;
    height: 75px;
    margin-bottom: 0px;
    overflow: hidden;
}
.partenaires-item h6{
    width: 100%;
    text-align: center;
}
.block-descr-loder{
    width: 100%;
    height: 10px;
    background-color: #eee;
    margin-bottom : 10px;
}
.loader-entreprise-text {
    width: 100px;
    height: 20px;
    background-color: #eee;
    display: inline-block;
    margin: 0;
    margin-bottom: -5px;
}
.loader-entreprise-image {
    width: 100%;
    background-color: #eee;
    height: 200px;
    margin-bottom: 20px;
}
.loader-entreprise-title {
    width: 100%;
    height: 40px;
    background-color: #eee;
}
.loader-entreprise-ecrire-a {
    width: 100%;
    height: 150px;
    background-color: #eee;
}
.loader-entreprise-produits-image {
    background-color: #eee;
}
.loader-entreprise-produits-text{
    background-color: #eee;
    width: 100%;
    height: 50px;
}
.modal-login-hadeer.modale-youtube>div>div{
    width: 100%!important;
    height: 60vh!important;
}

.block-search-entreprises>div>div>div,.block-search-categorie>div>div>div{
    border-radius: 0px;
}
.pagination {
    padding: 10px 0;
    justify-content: center;
    align-items: center;
}
.paginations .active a, .paginations .active span{
    background-color: red !important;
    color: #fff !important;
}
.paginations a, .paginations span {
    height: auto !important;
    padding: 5px !important;
    line-height: 20px;
}

.sue-menu .scroll-nav ul {
    margin-bottom: 15px;
}
.box-opening-hours{
    width: 100%;

}
.box-opening-hours li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-bottom: solid 1px #eee;
}
.show-desktop{
    display: block;
}
@media (max-width: 700px){
    #menu_mobile{
        display: block;
    }
    #menu_desktop{
        display: none;
    }
    .show-desktop{
        display: none;
    }
    #bottom_buttons{
        display: block;
    }
    .modal-devi-login>div>div{
        width: 100% !important;
    }
}

.page-detail-entreprises .presentation-body-item{
    height: 500px;
    overflow-x: auto;
}
.icon-no-follow{
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 25px;
    background-image: url(unfollow.png);
    border: solid 1px red;
    border-radius: 50px;
    color: red;
    padding: 0px 5px 0px 25px;
    display: block;
    width: -moz-fit-content;
    width: fit-content;
}
.contint-pack.sponsoriser {
    position: unset;
    height: 21px;
    border: solid 1px red;
    padding: 0px 10px;
    width: fit-content;
    border-top: unset;
    border-left: unset;
    border-radius: 5px 0px;
    color: red;
    opacity: 0.4;
}