
  .top-offres {
    width: 100%;
    background-color: #eee;
    padding: 5px;
    margin-bottom: 5px;
}
.top-offres .domaine-offres {
    width: fit-content;
    display: inline;
    text-align: right;
    text-transform: uppercase;
    color: red;
    font-weight: bold;
    float: left;
    margin-right: 5px;
    padding-right: 5px;
    border-right: solid 1px;
}
.top-offres .title-offres {
    width: 100%;
    display: block;
    text-align: left;
    font-weight: bold;
    color: #000;
}
.top-offres .budget-offres {
    width: 100%;
    display: block;
    text-align: left;
    font-size: 10px;
    font-weight: normal;
    color: #aaa;
}
.top-offres-body {
    padding-bottom: 5px;
    padding-top: 5px;
}
.top-entreprise-header {
    text-align: center;
}
.top-entreprise-header h2 {
    line-height: 20px;
}

li.li.li-domaine {
    text-align: left;
    color: red;
    width: 100%;
    padding: 0px 5px;
    background-color: #eee;
}

.loder-title {
    width: 95%;
    background-color: #eee;
    height: 20px;
    display: block;
}
.loder-image {
    width: 200px;
    height: 200px;
    background-color: #eee;
    margin-bottom: 0px;
}
.btn-postuler {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.btn-postuler span {
    border: 1px solid red;
    color: red;
    text-align: center;
    background: #fff;
    border-radius: 50px;
    height: 40px;
    line-height: 0px;
    width: 100%;
    padding: 20px;
    font-size: 20px;
    cursor: pointer;
}
.info p {
    text-align:center;
    color: #999;
    text-transform:none;
    font-weight:600;
    font-size:15px;
    margin-top:2px
    }
    .info i {
    color:#F6AA93;
    }
    .f1 h1 {
    font-size: 18px;
    padding: 22px 25px;
    border-radius: 5px 5px 0px 0px;
    margin: auto;
    text-shadow: none; 
    text-align:left
    }
    .f1 {
    border-radius: 5px;
    width:85%;
    margin: 5% auto;
    background-color: #FFFFFF;
    overflow: hidden;
    }
    .page-offre p span {
    color: #F00;
    }
    .page-offre p {
    margin: 0px;
    font-weight: 500;
    line-height: 2;
    color:#333;
    }
    .page-offre h1 {
    text-align:center; 
    color: #666;
    text-shadow: 1px 1px 0px #FFF;
    margin:50px 0px 0px 0px
    }
    .f1 input {
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #808284;
    margin-bottom: 15px;
    width: 100%;
    height: 40px;
    float: left;
    padding: 0px 15px;
    border-radius: 0;
    }

    .f1 a {
    text-decoration:inherit
    }
    textarea {
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #EEE;
    margin: 0;
    width: 100%;
    height: 130px; 
    float: left;
    padding: 0px 15px;
    }
    .form-group {
    overflow: hidden;
    clear: both;
    }
    .icon-case {
    width: 35px;
    float: left;
    border-radius: 5px 0px 0px 5px;
    background:#eeeeee;
    height:40px;
    position: relative;
    text-align: center;
    line-height:40px;
    }

    .contentform {
    width:100%;
    padding: 30px 0px 30px 10px;
    }
    .bouton-contact{
    background-color: #dd3333;
    height: 40px;
    color: #FFF;
    text-align: center;
    border: 0;
    padding: 10px 25px;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
    margin: 40px 25px 0px 0px;
    float: right;
    border-radius: 10px;
    }
    .leftcontact {
    width:49.5%; 
    float:left;
    border-right: 1px dotted #CCC;
    box-sizing: border-box;
    padding: 0px 15px 0px 0px;
    }
    .rightcontact {
    width:49.5%;
    float:right;
    box-sizing: border-box;
    padding: 0px 0px 0px 15px;
    }
    .validation {
    display:none;
    margin: 0 0 10px;
    font-weight:400;
    font-size:13px;
    color: #DE5959;
    }
    #sendmessage {
    border:1px solid #fff;
    display:none;
    text-align:center;
    margin:10px 0;
    font-weight:600;
    margin-bottom:30px;
    background-color: #EBF6E0;
    color: #5F9025;
    border: 1px solid #B3DC82;
    padding: 13px 40px 13px 18px;
    border-radius: 3px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
    }
    #sendmessage.show,.show  {
    display:block;
    }
    .span{
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #eee;
    margin-bottom: 15px;
    width: 100%;
    height: 40px;
    float: left;
    padding: 10px 15px;
    }
    .select{
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #eee;
    margin-bottom: 15px;
    width: 100%;
    height: 40px;
    float: left;
    padding: 10px 15px;
    }
    #Postuleriddd{
      display: none;
    }
    ::placeholder {
        color: #ccc;
      }
    .borde-block.blockmenu-block{
        min-height: 500px;
        max-height: 500px;
        overflow-x: auto ;
    }
    .borde-block.blockImageTitle-block{
        min-height: 500px;

    }
    