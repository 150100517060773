.title__bordered {
    text-align: center;
    text-transform: none;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 30px;
    color: #6e6c6f;
    background: #fff;
    padding-top: 15px;
    margin-top: 0px;
    position: relative;
    text-transform: uppercase;
}
.div-loginn {
    border: solid 1px #eee;
    margin: 20px;
    padding: 40px;
    height: 100%;
    padding-top: 10px;
    min-height: 500px;
}
.connxtion_header {
    text-align: center;
    justify-content: center;
}
.content-ihc {
    padding-top: 0px !important;
}
#error {
    background: transparent;
    color: #e11a18;
    padding: 10px;
    text-align: center;
    font-weight: 500;
    text-transform: capitalize;
}
.form-field2 {
    box-sizing: content-box;
    text-align: left;
    background-color: #fefefe;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    position: relative;
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    -o-border-radius: 4px !important;
    box-shadow: 2px 0px 30px rgb(0 0 0 / 0%);
    -webkit-box-shadow: 0px 0px 9px rgb(0 0 0 / 0%);
    -moz-box-shadow: 2px 0px 30px rgba(0,0,0,0);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
}
.form-field .fa {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 40px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: #eee;
}
.inputt {
    width: 100%;
    color: #555;
    padding: 8px 10px 7px;
    background-color: transparent !important;
    border: none !important;
    padding: 10px;
    display: inline-block;
    padding-left: 60px !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    -o-border-radius: 4px !important;
    height: 39px !important;
    margin-bottom: 0px !important;
    line-height: 1 !important;
}
.pass_eye {
    right: 0px;
    width: 40px;
    top: 0px;
    height: 40px;
    position: absolute;
    margin-top: 0px;
    cursor: pointer;
}
.btn-connecter {
    margin-bottom: 30px;
    height: 40px;
    cursor: pointer;
    margin-left: 10px;
}
.div_text_ou {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
}
.div_text_ou span {
    border: solid 1px #aaa;
    width: calc(40% - 25px);
    margin-top: 0px;
}
.div_text_ou .text-ou {
    border: none;
    width: 50px;
    display: block;
    text-align: center;
}
.GoogleLoginStyle{
    width: 100% !important;
    opacity: 0 !important;
    position: absolute;
    height: 40px;
    top: 0px;
    left: 0px;
}
.restPassInputt {
    margin-top: 30px;
    width: 100%;
    border: 1px solid #ccc !important;
    color: #555;
    background-color: transparent !important;
    padding: 10px !important;
    display: inline-block;
    font-size: 14px !important;
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    -o-border-radius: 4px !important;
    height: auto !important;
    margin-bottom: 0 !important;
    line-height: 1 !important;
}
.restPassButtnn {
    color: #fff !important;
    width: 100%;
    border: 0;
    background-color: #ed2829;
    box-shadow: none !important;
    font-family: Helvetica,sans-serif,'Trebuchet MS';
    border-radius: 3px;
    text-transform: capitalize;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: all .3s linear 0s;
    -moz-transition: all .3s linear 0s;
    -ms-transition: all .3s linear 0s;
    -o-transition: all .3s linear 0s;
    transition: all .3s linear 0s;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    min-height: 45px;
    font-size: 18px;
    -webkit-transition: all .3s linear 0s;
    -moz-transition: all .3s linear 0s;
    -ms-transition: all .3s linear 0s;
    -o-transition: all .3s linear 0s;
    transition: all .3s linear 0s;
}
.page-connecter .div_text_ou{
    margin-top:10px;
}