.top-entreprise {
    height: auto !important;
}
.top-entreprise-header img {
    margin-top: -10px;
    margin-bottom: 10px;
}
.top-entreprise-item.item {
    width: 100%;
    height: 280px;
}
.top-entreprise-sue-item {
    width: 49%;
    height: 140px;
    display: inline-block;
    padding: 2px;
    margin-left: 2px;
    float: left;
}
.top-entreprise-item.item .top-entreprise-sue-item figure {
    overflow: hidden;
    padding: 2px;
}
.top-entreprise-item.item .top-entreprise-sue-item span {
    width: 100%;
    height: 26px;
    margin: 0;
    font-weight: bold;
    color: #000;
    line-height: 9px;
    padding: 0;
    font-size: 11px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 5px;
    overflow: hidden;

}
.title-event {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    height: 55px;
    margin-bottom: 5px;
    overflow: hidden;
    text-align: center;
    color: #5b5b5b;
}
.date-event, .adresse-event {
    font-size: 11px;
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
    text-align: left;
    color: #3c3c3b !important;
}
.date-event span, .adresse-event span {
    font-weight: 700;
}
.top-evennementsItems .alice-carousel__prev-btn-item{
    background-color: transparent;
    color: red;
    font-weight: 900;
    font-size: 20px;
    margin-left: -10px;
}
.top-evennementsItems .alice-carousel__next-btn-item{
    background-color: transparent;
    color: red;
    font-weight: 900;
    font-size: 20px;
    margin-right: -10px;
}
.top-evennementsItems .alice-carousel__prev-btn,.top-evennementsItems .alice-carousel__next-btn{
    margin-top: -25px;
}
.top-entreprise-footer {
    text-align: end;
    font-weight: bold;
    margin-bottom: 5px;
}
.top-entreprise-footer a {
    color: red;
}
.item-top-loader{
    background-color: #eee;
    width: 100%;
    height: 200px;
}
.top-event-sue-item figure img{
    width: 100%;
    object-fit: cover;
    height: 140px;
}
.top-entreprise-item.item .top-entreprise-sue-item figure img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    margin: 0;
    padding: 0;
    transition: transform 1.9s;
}
.top-entreprise-item.item .top-entreprise-sue-item figure {
    margin: 0;
    padding: 0;
    border: solid 1px #aaa;
}