.loder-image-offre {
    width: 100%;
    height: 150px;
    background-color: #eee;
    margin: 5px;
}
.block-marker-loder{
    display: flex;
    justify-content: center;
}
.cont-img-offre {
    display: grid;
    height: 100%;
}