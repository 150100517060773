/* @import "react-alice-carousel/lib/alice-carousel.css"; */

.gallery-images .App {
    font-family: sans-serif;
    text-align: center;
  }

  .gallery-images img {
    max-width: 100%;
    object-fit:cover;
    height: 300px;
  }

  .gallery-images .wrapper {
    margin: 0 auto;
    text-align: center;
    width: 300px;
    height: 300px;
  }

  .gallery-images .thumb-container {
    background: red;
  }
.gallery-images .alice-carousel__prev-btn-item,.gallery-images  .alice-carousel__next-btn-item{
  background-color: transparent !important;
  color: #0b0b0b;
  font-size: 40px;
  /* margin-right: -15px;
  margin-left: -15px; */
}
.renderThumbs .alice-carousel__prev-btn-item,.renderThumbs .alice-carousel__next-btn-item{
  margin-top: 0px !important;
}

  .gallery-images ul {
    margin: 30px auto;
    text-align: center;
    width: 480px;
  }

  .gallery-images li {
    display: inline-block;
    width: 100px;
    cursor: pointer;
  }

  .gallery-images .thumb {
    height: 100px;
    margin: 0 5px 0 auto;
    text-align: center;
  }

  .gallery-images .chevLeft {
    float: left;
    font-size: 35px;
    margin-top: 22px;
    cursor: pointer;
  }

  .gallery-images .chevRight {
    float: right;
    font-size: 35px;
    margin-top: 22px;
    cursor: pointer;
  }

  .gallery-images ul.alice-carousel__dots {
    margin: 5px auto;
    overflow: hidden;
  }

  .gallery-images .modal {
    position: fixed;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  .gallery-images .hide {
    display: none;
  }

  .gallery-images button {
    display: block;
    margin: 5px auto;
  }

  .gallery-images .thumb-list {
    height: 150px;
  }
.gallery-images {
    zoom: 90%;
}
.loder-gallery-image-min {
  width: 100px;
  height: 100px;
  background-color: #eee;
  margin-right: 10px;
}

.loder-gallery-images {
  margin-top: 20px;
  display: flex;
}
.loder-gallery-image {
  width: 100%;
  height: 300px;
  background-color: #eee;
}

.gallery-images .wrapper{
  width: 100%;
}
.gallery-images .alice-carousel__stage{
  margin-top: 0px;
}
