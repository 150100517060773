.wpnaw-link-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}
.wpnaw-news-image {
    height: 200px;
    width: 100% !important;
    object-fit: cover;
    object-position: top center;
}
.wpnaw-news-grid-content {
    background: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.7));
    box-sizing: border-box;
    padding: 15px;
    position: absolute;
    top: 0;
    height: 200px;
    width: calc(100% - 30px);
}
.wpnaw-news-categories {
    padding: 4px 0;
    display: inline-block;
    text-transform: uppercase;
    color: #666;
    z-index: 9;
    position: relative;
}
.wpnaw-news-date {
    color: #fff;
}
.wpnaw-title-content {
    position: absolute;
    z-index: 2;
    bottom: 0;
    clear: both;
    left: 15px;
    right: 15px;
    padding-bottom: 15px;
}
.wpnaw-news-content {
    padding: 15px 0;
}
.wpnaw-news-categories span:nth-child(4n+1) {
    background: #ed2829;
}
.wpnaw-news-categories span {
    text-decoration: none;
    -moz-transition: all .5s ease-out 0s;
    -ms-transition: all .5s ease-out 0s;
    -o-transition: all .5s ease-out 0s;
    transition: all .5s ease-out 0s;
    font-size: 10px;
    text-transform: uppercase;
    padding: 3px 8px;
    color: #fff !important;
    line-height: normal;
    display: inline-block;
    margin: 0 0 2px 0;
    background: #333;
}
h2.wpnaw-news-title {
    margin-bottom: -10px;
}
.wpnaw-news-title {
    font-size: 15px !important;
    font-family: calibri;
    padding-bottom: 0 !important;
}
.wpnaw-news-title a {
    color: #FFF;
    text-decoration: none !important;
    border-bottom: 0 !important;
}
.readmorebtn {
    -moz-transition: all .5s ease-out 0s;
    -ms-transition: all .5s ease-out 0s;
    -o-transition: all .5s ease-out 0s;
    transition: all .5s ease-out 0s;
    text-decoration: none !important;
    margin-bottom: 5px;
    color: #888;
    border: 1px solid #bfbfbf;
    padding: 4px 20px;
    font-family: calibri;
    font-size: 12px;
    display: inline-block;
    box-sizing: border-box;
    line-height: normal !important;
    margin: 15px 0 8px;
}
.de-search-form{
    border: none;
}
.borde-block {
    padding: 15px;
    background: #fff;
    border: 1px solid #b8c7c8;
    height: 100%;
}
.lowder-image-post {
    height: 200px;
    background-color: #eee;
}
.lowder-text-post {
    width: 100%;
    height: 50px;
    background-color: #eee;
}
@media screen and (max-width: 720px) {
    .btn-search-actualite{
        position: absolute;
        top: 1px;
        right: 16px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
}