
.loder-title-blog {
    width: 100%;
    height: 70px;
    background-color: #eee;
}
.loder-image-blog{
    background-color: #eee;
}
.loder-title-lingn-blog{

    width: 100%;
    height: 20px;
    background-color: #eee;
}
@media only screen and (max-width: 760px){
    .page-detail-actualite .wpnaw-news-image{
        height: 200px !important;
    }
}