.offres-similaire-mission img{
    height: 140px !important;
    object-fit: cover !important;
}
.figure-attachment-offres{
    width: 100%;
    height: 140px;
    background-color: #eee;
}
.page-offre .alice-carousel p span{
    color: #fff;
}