.page-offre-sous-tritance .wpnaw-news-image {
    height: 250px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border: solid 1px #eee;
}
.page-offre-sous-tritance .wpnaw-news-grid-content{
    background-color: transparent !important;
    background:none;
    padding-left: 0px;
}
.page-offre-sous-tritance h2.wpnaw-news-title {
    margin: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.page-offre-sous-tritance .wpnaw-news-title a {
    color: #555;
    text-decoration: none !important;
    border-bottom: 0 !important;
}
.form-control-objet{
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #EEE !important;
    margin: 0;
    width: 100%;
    height: 40px;
    float: left;
    padding: 0px 15px;
    margin-bottom: 0px !important;
}

.title-offres-sous-traitance-similaires{
    color: '#000';
    text-transform: 'capitalize';
    font-size: 12px;
    line-height: '12px';
    margin-top: '12px';
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}