.size-titre-b2b{
    font-weight: 700 !important;
}
.block-prix td.table-titel {
    background-color: #eee;
    border: solid 1px #aaa;
    font-weight: bold;
}
.block-prix table td {
    padding: 15px 10px;
}
.block-prix td.table-info {
    background-color: white;
    border: solid 1px #aaa;
    font-weight: bold;
    color: red;
}
.product-description .nav-item{
    width: auto;
    padding-bottom: 0px !important;
}
.product-description .nav-item>a{
    margin-left: 0px;
    margin-right: 0px;
}
.product-description .nav-item>a>.fa{
    float: left;
    margin-top: 3px;
}


.product-description .tab-content {
    margin-top: 20px;
}

.chevRight::after,.chevLeft::after{
    content: ">";
    width: 20px;
    height: 20px;
    color: red;
  
}
.chevLeft::after{
    content: "<";
}
.btn-info-company {
    border: solid 1px red;
    border-radius: 50px;
    color: red;
}
#block_info_company .produit-aussi figure {
    border: solid 1px #aaa;
    height: 100px;
}
#block_info_company .produit-aussi figure img {
    height: 100% !important;
    width: 100%;
    margin: auto;
    object-fit: contain;
}
#block_info_company .produit-aussi .item h6 {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
}
#block_info_company .produit-aussi li>div.item{
    padding: 2px;
}
#block_info_company .produit-aussi .alice-carousel__prev-btn{
    top: 30px;
    margin-left: -25px;
}
#block_info_company .produit-aussi .alice-carousel__next-btn{
    top: 30px;
    margin-right: -25px;
}
#block_info_company .produit-aussi .alice-carousel__prev-btn .alice-carousel__prev-btn-item,#block_info_company .produit-aussi .alice-carousel__next-btn .alice-carousel__next-btn-item{
    background-color: transparent;
    color: red;
    font-weight: 700;
}

.loder-prod-InfoCompany {
    height: calc(100% - 4px);
    background-color: #eee;
    margin: 2px;
}
.loder-produi-aussi-image {
    width: 100%;
    height: 200px;
    background-color: #eee;
    padding: 5px;
}
.loder-produi-aussi-name {
    width: 100%;
    height: 50px;
    background-color: #eee;
    padding: 5px;
}
.modale-info-company .figure {
    display: flex;
    justify-content: center;
}
.modale-info-company .figure img {
    height: 200px;
    width: auto !important;
    object-fit: cover;
}
.modale-info-company .company-sociaux{
    display: flex;
    justify-content: center;
    align-items: center;
}
.modale-info-company .company-sociaux a{
    background-color: red;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin: 0px 2px;
}
.text-titel-pupup-info-omp.place_post_title{
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: #3c3c3b;
}
.modale-info-company #horaires_de_travail .box-widget {
    background: #fff;
    border: 1px solid #b8c7c8;
    overflow: hidden;
    width: 100%;
}
.modale-info-company #horaires_de_travail .box-widget-content {
    width: 100%;
    position: relative;
    padding: 25px 30px 30px;
}
.modale-info-company #horaires_de_travail .opening-hours ul li {
    width: 100%;
    margin-bottom: 15px;
    padding-bottom: 15px;
    color: #878c9f;
    border-bottom: 1px solid #eee;
    list-style: none;
    display: flex;
    justify-content: space-between;
}
.modale-info-company #horaires_de_travail .opening-hours ul li span.opening-hours-day {
    color: #878c9f;
    font-weight: 600;
}
.modale-info-company #horaires_de_travail .opening-hours ul li span.opening-hours-time {
    font-weight: 500;
    color: #999;
}
.cursor-pointer{
    cursor: pointer !important;
}
.modale-info-company #horaires_de_travail .toujoursouvert{
    width: 100%;
    text-align: center;
    padding: 20px;
    font-weight: 600;
}

.partenaires-item.item img{
    width: 100%;
    height: 200px;
    object-fit: contain;
    padding: 5px;
    border-left: solid 1px #7c7c7c55;
}
.loder-from {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    z-index: 999;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.sund-form-sucsses{
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    padding: 10px 5px;
}
.listes-avis-clients {
    max-height: 300px;
    overflow-x: auto;
}
.listes-avis-clients ol.commentlist li .comment-text {
    padding-left: 10px;
    width: calc(100% - 80px);
}
.listes-avis-clients .comment_container {
    display: flex;
    justify-content: start;
    align-items: center;
}
.listes-avis-clients li{
    height: auto;
}
.listes-avis-clients ol.commentlist li .star-rating {
    position: absolute;
    top: 0px;
    right: 5px;
    width: 120px;
}
.listes-avis-clients li span{
    min-width: auto;
}
.listes-avis-clients ol.commentlist li p{
    margin-top: 5px !important;
}
.listes-avis-clients li{
    padding: 5px;
    padding-top: 20px;
}