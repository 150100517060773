.tab {
    overflow: hidden;
    border: 1px solid #ffffff;
    background-color: #ffffff;
}
.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 15px;
    width: 33%;
    text-transform: uppercase;
    text-align: center;
}
.tab button:hover {
    background-color: #ED2829;
    border: 1px solid #ED2829;
    color: #fff;
}
.tab button.active {
    background-color: #fff;
    border: 1px solid #ED2829;
    color: #ED2829;
}

.mec-wrap {
    font: 14px/25px sans-serif;
    font-family: Montserrat,Helvetica,Arial,sans-serif;
    font-weight: 400;
    color: #626262;
}
.mec-wrap, .mec-wrap div {
    font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.mec-wrap * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.mec-event-list-standard .mec-event-article {
    position: relative;
    display: block;
    margin-bottom: 25px;
    border: 1px solid #e9e9e9;
    box-shadow: 0 2px 0 0 rgb(0 0 0 / 2%);
}
.mec-event-list-standard .mec-topsec {
    width: 100%;
}
.mec-event-list-standard .mec-col-table-c.mec-event-image-wrap {
    vertical-align: top;
}
.mec-event-list-standard .col-md-3.mec-event-image-wrap {
    padding-left: 0;
}
.mec-event-list-standard .mec-event-content {
    padding-top: 15px;
    padding-right: 30px;
}
.mec-event-list-standard .mec-col-table-c.mec-event-meta-wrap {
    padding-top: 15px;
    height: 155px;
}
.mec-event-list-standard .mec-date-details, .mec-event-list-standard .mec-time-details, .mec-event-list-standard .mec-venue-details {
    position: relative;
    padding-left: 28px;
    margin-bottom: 10px;
}
.mec-event-image,.mec-event-image img{
    height: 150px;
}
@media (max-width: 700px){
    .menu-evenements-mobile{
        zoom: 80%;
    }
}