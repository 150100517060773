.btn-evenement{
    border-color: #e11a18!important;
    background-color: #ffffff!important;
    color: #e11a18!important;
    border: solid 1px;
    padding: 10px;
    height: auto;
}
h1.mec-single-title {
    text-align: unset;
    font-size: xx-large;
    color: #171717;
}
.mec-single-title.loder{
    background-color: #eee;
    width: 70%;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.event-description-loder{
    background-color: #eee;
    width: 100%;
    height: 15px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 10px;
}

.mec-event-meta.image-loder{
    background-color: #eee;
    width: 100%;
    height: 250px;
    border-radius: 5px;

}
.partagez-loder{
    background-color: #eee;
    width: 150px;
    height: 50px;
}
.span-loder span{
    background-color: #eee;
    width: 150px;
    height: 20px;
    display: block;
}
.span-loder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.loder-content-event-avenir{

}
.loder-title-event-avenir{

}
.loder-image-event-avenir{
    
}