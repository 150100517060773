/**
*	Ryan - Resume/CV/vCard Template (HTML)
*	Version: 2.5
*	Author: beshleyua
*	Author URL: http://themeforest.net/user/beshleyua
*	Copyright © Cvio by beshleyua. All Rights Reserved.
**/


/* TABLE OF CONTENTS
	1. Base
	2. Container
	3. Columns
	4. Preloader
	5. Typography
	6. Forms
	7. Alignments
	8. Animations
*/


/*
	1. Header
*/

.header-expert {
    position: absolute;
    left: -80px;
    top: 15px;
    width: 72px;
    z-index: 100;
    text-align: center;
    opacity: 1;
    visibility: visible;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
}

.header.active {
    opacity: 1!important;
    visibility: visible!important;
}

.header-expert .menu-btn {
    margin-bottom: 8px;
    position: relative;
    display: block;
    height: 72px;
    text-align: center;
    background: #ffffff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    z-index: 2;
}

.no-sidebar .header-expert .menu-btn,
.disable-sidebar .header-expert .menu-btn {
    display: none;
}

.header-expert .menu-btn span {
    margin-left: -13px;
    margin-top: -1px;
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 26px;
    height: 2px;
    background: #323232;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.header-expert .menu-btn span:before,
.header-expert .menu-btn span:after {
    content: '';
    position: absolute;
    left: 0;
    top: -7px;
    width: 80%;
    height: 100%;
    background: #323232;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.header-expert .menu-btn span:after {
    top: auto;
    bottom: -7px;
    width: 60%;
}

.header-expert .menu-btn:hover span,
.header-expert .menu-btn:hover span:before,
.header-expert .menu-btn:hover span:after {
    background: #78cc6d;
}

.header-expert .menu-btn:hover span:before {
    width: 100%;
}

.header-expert .menu-btn:hover span:after {
    width: 100%;
}

.header-expert .top-menu {
    width: 100%;
    font-size: 0;
}

.header-expert .top-menu ul li {
    display: block;
    transition: color 0.3s ease 0s;
    -moz-transition: color 0.3s ease 0s;
    -webkit-transition: color 0.3s ease 0s;
    -o-transition: color 0.3s ease 0s;
}

.header-expert .top-menu ul li:first-child a {
    border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    -khtml-border-radius: 4px 4px 0 0;
}

.header-expert .top-menu ul li:last-child a {
    border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    -khtml-border-radius: 0 0 4px 4px;
    border: none;
}

.header-expert .top-menu ul li:last-child a:before {
    display: none;
}

.header-expert .top-menu ul li a {
    position: relative;
    overflow: hidden;
    display: block;
    height: 72px;
    color: #171717;
    text-align: center;
    background: #ffffff;
}

.header-expert .top-menu ul li a:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: -moz-radial-gradient(right, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(right, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at right, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
}

.header-expert .top-menu ul li a .icon {
    margin: -27px 0 0 -20px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: #171717;
    line-height: 40px;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    -khtml-border-radius: 30px;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.header-expert .top-menu ul li a .link {
    position: absolute;
    left: 0;
    top: 57%;
    width: 100%;
    display: block;
    font-size: 10.5px;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.header-expert .top-menu ul li:hover a,
.header-expert .top-menu ul li.active a {
    color: #78cc6d;
}

.header-expert .top-menu ul li:hover a .icon,
.header-expert .top-menu ul li.active a .icon,
.header-expert .top-menu ul li:hover a .link,
.header-expert .top-menu ul li.active a .link {
    color: #78cc6d;
}

.header-expert .profile {
    padding: 10px 62px 10px 18px;
    width: auto;
    height: 64px;
    text-align: left;
    display: none;
}

.header-expert .profile .image {
    margin-right: 14px;
    float: left;
    width: 44px;
    font-size: 0;
}

.header-expert .profile .image img {
    width: 100%;
    height: auto;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}

.header-expert .profile .title {
    font-size: 15px;
    color: #323232;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.header-expert .profile .subtitle {
    font-size: 12px;
    color: #78cc6d;
    line-height: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.no-sidebar .header-expert .menu-btn,
.disable-sidebar .header-expert .menu-btn {
    display: none;
}

@media (max-width: 1023px) {
    .header-expert {
        position: fixed;
        left: 0;
        top: -4px;
        width: 100%;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .header-expert .top-menu {
        margin: 0 auto;
        max-width: 540px;
        display: flex;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
    }
    .header-expert .top-menu ul {
        display: flex;
        width: 100%;
    }
    .header-expert .top-menu ul li {
        display: block;
        width: 100%;
    }
    .header-expert .top-menu ul li:first-child a {
        border-radius: 4px 0 0 4px;
        -moz-border-radius: 4px 0 0 4px;
        -webkit-border-radius: 4px 0 0 4px;
        -khtml-border-radius: 4px 0 0 4px;
    }
    .header-expert .top-menu ul li:last-child a {
        border-radius: 0 4px 4px 0;
        -moz-border-radius: 0 4px 4px 0;
        -webkit-border-radius: 0 4px 4px 0;
        -khtml-border-radius: 0 4px 4px 0;
    }
    .header-expert .top-menu ul li a:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background: -moz-radial-gradient(top, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
        background: -webkit-radial-gradient(top, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
        background: radial-gradient(ellipse at top, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    }
    .header-expert .top-menu ul li:last-child a:before {
        display: block;
    }
    .disable-sidebar.no-sticky-menu .header-expert .menu-btn,
    .no-sidebar .no-sticky-menu .header-expert .menu-btn {
        height: 62px;
        visibility: visible;
        opacity: 1;
        display: block;
    }
}

@media (max-width: 560px) {
    .header-expert {
        padding: 0 5px;
        width: 100%;
    }
    .header-expert .top-menu {
        max-width: 100%;
    }
    .header-expert .profile {
        display: block;
    }
    .header-expert .menu-btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 62px;
        height: 62px;
    }
}


/*
	2. Card - Started
*/

.card-started {
    position: relative;
    width: 100%;
    height: 600px;
    z-index: 11;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
}

.card-started:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    border-radius: 0 0 4px 0;
    -moz-border-radius: 0 0 4px 0;
    -webkit-border-radius: 0 0 4px 0;
    -khtml-border-radius: 0 0 4px 0;
}

.card-started:after {
    content: '';
    position: absolute;
    left: -15px;
    top: -15px;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(-45deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    background: linear-gradient(135deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
}

.card-started .profile {
    padding: 210px 50px 50px 50px;
    position: relative;
    height: 100%;
    background: #ffffff;
    text-align: center;
    z-index: 10;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
}

.card-started .profile.no-photo {
    padding-top: 340px;
}

.card-started .profile.no-photo .image {
    display: none;
}

.card-started .profile .slide {
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 100%;
    height: 300px;
    background-color: #626262;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    -khtml-border-radius: 4px 4px 0 0;
}

.card-started .profile .slide:before,
.card-started .profile .slide:after {
    content: '';
    position: absolute;
    left: -25%;
    bottom: -198px;
    width: 700px;
    height: 200px;
    background: #ffffff;
    transform: rotate(12deg);
    -webkit-transform: rotate(12deg);
    -moz-transform: rotate(12deg);
    -o-transform: rotate(12deg);
}

.card-started .profile .slide:after {
    left: auto;
    right: -25%;
    transform: rotate(-6deg);
    -webkit-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
}

.card-started .profile .image {
    margin: 0 0 20px 0;
    position: relative;
    display: inline-block;
    width: 140px;
}

.card-started .profile .image:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(-45deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    background: linear-gradient(135deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -khtml-border-radius: 100%;
}

.card-started .profile .image img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    border: 3px solid #ffffff;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -khtml-border-radius: 100%;
}

.card-started .profile .title {
    margin: 0 0 10px 0;
    font-size: 32px;
    color: #171717;
    line-height: 32px;
    font-weight: 400;
}

.card-started .profile .subtitle {
    margin: 0 0 15px 0;
    font-size: 14px;
    color: #78cc6d;
    line-height: 14px;
    font-weight: 400;
}

.card-started .profile .social a {
    margin: 0 6px;
    display: inline-block;
    vertical-align: middle;
}

.card-started .profile .social a .ion,
.card-started .profile .social a .fab,
.card-started .profile .social a .fas {
    font-size: 16px;
    color: #171717;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.card-started .profile .social a:hover .ion,
.card-started .profile .social a:hover .fab,
.card-started .profile .social a:hover .fas {
    color: #78cc6d;
}

.card-started .profile .lnk.active.discover .arrow {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
}

@media (max-width: 560px) {
    .card-started {
        height: 480px;
    }
    .card-started:after {
        display: none;
    }
    .card-started .profile {
        padding: 135px 25px 25px 25px;
    }
    .card-started .profile.no-photo {
        padding-top: 250px;
    }
    .card-started .profile .slide {
        height: 200px;
    }
    .card-started .profile .image {
        margin: 0 0 15px 0;
        width: 124px;
    }
    .card-started .profile .title {
        font-size: 28px;
        line-height: 28px;
    }
}


/*
	3. Cards
*/

.card-inner {
    padding: 0;
    position: absolute;
    overflow: hidden;
    left: 95%;
    top: 15px;
    width: 120%;
    height: 570px;
    background: #ffffff;
    transform-origin: center left;
    opacity: 0;
    visibility: hidden;
    z-index: 8;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    transition: visibility 1s linear, opacity 0.5s linear;
    -moz-transition: visibility 1s linear, opacity 0.5s linear;
    -webkit-transition: visibility 1s linear, opacity 0.5s linear;
    -o-transition: visibility 1s linear, opacity 0.5s linear;
}

.card-inner.animated {
    opacity: 1;
    visibility: visible;
    z-index: 9;
}

.card-inner.active {
    opacity: 1;
    visibility: visible;
    z-index: 10;
}

.card-inner.hidden {
    opacity: 0;
    visibility: hidden;
    z-index: 9;
}

.card-inner:before,
.card-inner:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 20px;
    height: 20px;
    background: #ffffff;
    z-index: 10;
}

.card-inner:after {
    top: auto;
    bottom: 0;
}

.card-inner .card-wrap {
    padding: 30px 30px 30px 60px;
    position: relative;
    overflow-x: auto;
    /* height: 100%; */
}

@media (max-width: 1072px) {
    .card-inner {
        width: 115%;
    }
}

@media (max-width: 1023px) {
    .card-inner {
        position: relative;
        margin: 15px 0;
        left: 0;
        top: 0;
        width: auto;
        height: auto;
        opacity: 1;
        visibility: visible;
    }
    .card-inner .card-wrap {
        padding: 30px 30px 30px 40px;
        overflow: visible;
        height: auto;
    }
    .card-inner:last-child {
        margin-bottom: 50px;
    }
}

@media (max-width: 560px) {
    .card-inner {
        margin: 10px 0;
    }
    .card-inner .card-wrap {
        padding: 30px;
    }
    .card-inner:last-child {
        margin-bottom: 10px;
    }
}


/* content */

.content {
    margin: 0 0 60px 0;
    position: relative;
}

.content:last-child {
    margin-bottom: 0;
}

.content .title {
    margin: 0 0 15px 0;
    padding: 0 0 5px 0;
    position: relative;
    font-size: 17px;
    color: #171717;
    line-height: 21px;
    font-weight: 500;
}

.content .title:before {
    content: '';
    position: absolute;
    left: -12px;
    top: 0;
    width: 30px;
    height: 30px;
    background: -moz-linear-gradient(-45deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    background: linear-gradient(135deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    z-index: -1;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    -khtml-border-radius: 30px;
}

.content .title:after {
    content: '';
    position: absolute;
    left: -60px;
    bottom: -15px;
    width: 95%;
    height: 1px;
    background: -moz-radial-gradient(left, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(left, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at left, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
}


/*
	4. Card - About
*/


/* 4.1 About */

.info-list ul li {
    display: inline-block;
    width: 48%;
    line-height: 1.6;
}

.info-list ul li strong {
    display: inline-block;
    font-size: 13px;
    color: #171717;
    font-weight: 500;
    text-transform: uppercase;
}

@media (max-width: 560px) {
    .info-list ul li {
        display: block;
        width: auto;
    }
}


/* 4.2 Services */

.service-items {
    font-size: 0;
}

.service-items.border-line-v:after {
    margin: 0 0 0 -1px;
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 1px;
    height: 100%;
    background: -moz-radial-gradient(top, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(top, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at top, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
}

.service-items .service-item {
    text-align: left;
}

.service-items .service-item .icon {
    margin: 0;
    width: 60px;
    height: 60px;
    text-align: center;
    font-size: 32px;
    color: #78cc6d;
    background: -moz-linear-gradient(-45deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    background: linear-gradient(135deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    -khtml-border-radius: 60px;
}

.service-items .service-item .icon .ion,
.service-items .service-item .icon .fab,
.service-items .service-item .icon .fas {
    display: block;
    line-height: 60px;
}

.service-items .service-item .name {
    margin: 15px 0 10px 0;
    font-size: 13px;
    color: #171717;
    font-weight: 500;
    text-transform: uppercase;
}

.service-items .service-item p {
    margin: 0;
}

@media (max-width: 560px) {
    .service-items.border-line-v:after {
        display: none;
    }
    .service-items .col:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: -moz-radial-gradient(left, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
        background: -webkit-radial-gradient(left, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
        background: radial-gradient(ellipse at left, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    }
}


/* 4.3 Pricing */

.pricing-items {
    font-size: 0;
}

.pricing-items .pricing-item {
    padding: 0 0 70px 0;
    text-align: center;
}

.pricing-items .pricing-item .icon {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 32px;
    color: #78cc6d;
}

.pricing-items .pricing-item .icon .ion,
.pricing-items .pricing-item .icon .fab,
.pricing-items .pricing-item .icon .fas {
    display: block;
    line-height: 40px;
}

.pricing-items .pricing-item .name {
    margin: 0 0 30px 0;
    font-size: 13px;
    color: #171717;
    font-weight: 500;
    text-transform: uppercase;
}

.pricing-items .pricing-item .amount {
    margin: 0 0 25px 0;
}

.pricing-items .pricing-item .amount .number {
    margin: 0;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 42px;
    line-height: 26px;
    font-weight: 500;
    color: #171717;
}

.pricing-items .pricing-item .amount .dollar,
.pricing-items .pricing-item .amount .period {
    margin: 0 3px;
    position: relative;
    bottom: 7px;
    display: inline-block;
    vertical-align: top;
    font-size: 17px;
    font-weight: 400;
    color: #171717;
}

.pricing-items .pricing-item .amount .period {
    bottom: auto;
    top: 7px;
    vertical-align: bottom;
    font-size: 13px;
    color: #626262;
}

.pricing-items .pricing-item .feature-list ul li {
    padding: 5px 0;
    font-size: 14px;
    color: #171717;
}

.pricing-items .pricing-item .feature-list ul li:last-child {
    border-bottom: none;
}

.pricing-items .pricing-item .feature-list ul li.disable {
    text-decoration: line-through;
    opacity: 0.6;
}

.pricing-items .pricing-item .feature-list ul li strong {
    margin: 0 0 0 5px;
    padding: 0 5px;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    color: #171717;
    background: rgba(120, 204, 109, 0.4);
    font-weight: 300;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -khtml-border-radius: 2px;
}

.pricing-items .pricing-item .lnks .lnk {
    float: none;
    width: 100%;
    display: block;
}

.pricing-items .pricing-item .speed-basic {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
}


/* 4.4 Fun Fact */

.fuct-items {
    font-size: 0;
}

.fuct-items .fuct-item {
    position: relative;
    text-align: center;
}

.fuct-items .fuct-item .icon {
    margin: 0 auto;
    width: 60px;
    height: 60px;
    text-align: center;
    font-size: 32px;
    color: #78cc6d;
}

.fuct-items .fuct-item .icon .ion,
.fuct-items .fuct-item .icon .fab,
.fuct-items .fuct-item .icon .fas {
    display: block;
    line-height: 60px;
}

.fuct-items .fuct-item .name {
    margin: 0;
    font-size: 13px;
    color: #171717;
    font-weight: 500;
    text-transform: uppercase;
}


/* 4.5 Clients */

.client-items {
    font-size: 0;
    text-align: center;
}

.client-items .client-item img {
    max-width: 92px;
    opacity: 0.4;
}


/*
	5. Resume
*/

.resume-title,
.skill-title {
    padding: 0 0 20px 0;
    position: relative;
}

.resume-title:after,
.skill-title:after {
    left: -20px;
}

.resume-title .icon,
.skill-title .icon {
    position: relative;
    top: -15px;
    float: left;
    width: 40px;
    height: 40px;
    font-size: 32px;
    color: #78cc6d;
    background: #ffffff;
}

.resume-title .icon .ion,
.skill-title .icon .ion,
.resume-title .icon .fab,
.skill-title .icon .fab,
.resume-title .icon .fas,
.skill-title .icon .fas {
    display: block;
    line-height: 40px;
}

.resume-title .name,
.skill-title .name {
    margin: 0 0 0 40px;
    font-size: 13px;
    line-height: 13px;
    color: #171717;
    font-weight: 500;
    text-transform: uppercase;
}


/* 5.1 Resume */

.resume-items .resume-item {
    position: relative;
    padding: 20px 0 20px 0;
}

.resume-items .resume-item:after {
    left: -20px;
}

.resume-items .resume-item.active .date {
    color: #78cc6d;
}

.resume-items .resume-item.active .date:before {
    background: #78cc6d;
}

.resume-items .resume-item .date {
    margin: 0 0 10px 0;
    padding: 0;
    position: relative;
    display: inline-block;
    font-size: 11px;
    line-height: 18px;
    color: #d8dbe2;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
}

.resume-items .resume-item .date:before {
    content: '';
    position: absolute;
    left: -25px;
    top: 4px;
    width: 9px;
    height: 9px;
    background: #d8dbe2;
    border-radius: 9px;
    -moz-border-radius: 9px;
    -webkit-border-radius: 9px;
    -khtml-border-radius: 9px;
}

.resume-items .resume-item .name {
    margin: 0;
    font-size: 13px;
    color: #171717;
    font-weight: 500;
    text-transform: uppercase;
}

.resume-items .resume-item .company {
    margin: 0 0 10px 0;
    font-size: 11px;
    color: #999999;
    font-weight: 300;
    text-transform: uppercase;
}

.resume-items .resume-item p {
    margin: 0;
}


/* 5.2 Skills */

.skills-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.skills-list ul li {
    position: relative;
    padding: 20px 0;
}

.skills-list ul li:after {
    left: -20px;
}

.skills-list ul li .name {
    margin: 0 0 8px 0;
    font-size: 14px;
    line-height: 14px;
    color: #626262;
}

.skills-list ul li .progress {
    position: relative;
    display: block;
    width: 100%;
    height: 4px;
    background: #d8dbe2;
}

.skills-list ul li .progress .percentage {
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: #78cc6d;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}


/*
	6. Works
*/

.content.works .filter-menu {
    position: absolute;
    top: -9px;
    right: 0;
}

.content.works .filter-menu .f_btn {
    margin: 0 0 0 15px;
    padding: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    color: #626262;
    cursor: pointer;
    border: none;
}

.content.works .filter-menu .f_btn.active {
    color: #78cc6d;
}

.content.works .filter-menu .f_btn label {
    cursor: pointer;
}

.content.works .grid-items.border-line-v:after {
    margin: 0 0 0 -1px;
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 1px;
    height: 100%;
    background: -moz-radial-gradient(top, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(top, ellipse cover, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at top, rgba(197, 202, 213, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
}

@media (max-width: 560px) {
    .content.works .title {
        padding: 0 0 45px 0;
    }
    .content.works .filter-menu {
        top: 35px;
        left: 0;
        right: auto;
    }
    .content.works .filter-menu button {
        margin: 0 15px 0 0;
    }
    .content.works .grid-items.border-line-v:after {
        display: none;
    }
}


/* 6.1 Box Items */

.box-item {
    position: relative;
    overflow: hidden;
    text-align: center;
}

.box-item .image {
    position: relative;
}

.box-item .image a {
    display: block;
    font-size: 0;
}

.box-item .image a img {
    position: relative;
    width: 100%;
    height: auto;
}

.box-item .image .info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.box-item .image .info:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(-45deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    background: linear-gradient(135deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.box-item .image .info .ion,
.box-item .image .info .fab,
.box-item .image .info .fas {
    margin: -16px 0 0 -16px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 32px;
    height: 32px;
    font-size: 32px;
    line-height: 32px;
    color: #ffffff;
    transition: all 0.1s ease-in 0.4s;
    -moz-transition: all 0.1s ease-in 0.4s;
    -webkit-transition: all 0.1s ease-in 0.4s;
    -o-transition: all 0.1s ease-in 0.4s;
}

.box-item .desc {
    padding: 15px 45px 0 45px;
    position: relative;
}

.box-item .desc .name {
    padding: 0 0 2px 0;
    display: block;
    font-size: 13px;
    color: #171717;
    font-weight: 500;
    text-transform: uppercase;
}

.box-item .desc .category {
    margin: 0;
    font-size: 11px;
    color: #999999;
    font-weight: 300;
    text-transform: uppercase;
}

.box-item .date {
    padding: 10px 0 0 0;
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 50px;
    height: 50px;
    font-size: 11px;
    line-height: 11px;
    color: #171717;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    background: #ffffff;
}

.box-item .date strong {
    display: block;
    font-size: 20px;
    line-height: 20px;
    color: #171717;
    font-weight: 700;
}

.box-item:hover .image .info {
    opacity: 1;
}


/*
	6. Contacts
*/

.content.contacts .map {
    margin: 0 0 20px 0;
    height: 190px;
}

.alert-success {
    display: none;
}

.alert-success p {
    margin: 0;
    font-size: 17px;
}


/*
	7. Popups
*/

.mfp-wrap.popup-box-inline {
    margin: 0 0 0 -360px;
    padding: 0;
    top: 0;
    left: 50%;
    overflow: auto;
    width: 720px;
    height: 100%;
    background: #ffffff;
}

.mfp-wrap.popup-box-inline .mfp-container {
    padding: 0;
    position: relative;
    height: auto;
}

.mfp-wrap.popup-box-inline .content {
    margin-bottom: 40px;
}

.mfp-wrap.popup-box-inline .image {
    padding: 0 0 40px 0;
    font-size: 0;
}

.mfp-wrap.popup-box-inline .image img {
    width: 100%;
    height: auto;
}

.mfp-wrap.popup-box-inline .desc {
    padding: 0 40px;
}

.mfp-wrap.popup-box-inline .desc .post-box .blog-content {
    margin: 0;
}

.mfp-wrap.popup-box-inline .desc .lnk,
.mfp-wrap.popup-box-inline .desc .button {
    height: 30px;
    line-height: 30px;
}

@media (max-width: 720px) {
    .mfp-wrap.popup-box-inline {
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0 10px;
    }
}

.mfp-wrap.popup-box .mfp-close,
.mfp-wrap.popup-box-inline .mfp-close {
    padding: 0;
    right: -54px;
    top: 0;
    width: 54px;
    height: 54px;
    line-height: 54px;
    font-family: 'Poppins';
    color: #ffffff;
    font-size: 42px;
    font-weight: 300;
    text-align: center;
    opacity: 1;
}

@media (max-width: 720px) {
    .mfp-wrap.popup-box .mfp-close,
    .mfp-wrap.popup-box-inline .mfp-close {
        right: 0;
        top: -54px;
    }
}

.mfp-wrap.popup-box-inline .mfp-close {
    top: 0;
    right: 0;
    color: #171717;
}

@media (max-width: 720px) {
    .mfp-wrap.popup-box-inline .mfp-close {
        right: 0;
        top: 0;
    }
}

.card-inner.blog-post {
    -webkit-animation: none;
    animation: none;
}

.content.works .filter-menu .f_btn input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}


/* new styles */

.header-expert .top-menu ul li a {
    padding: 10px 0 14px 0;
    height: auto;
}

.header-expert .top-menu ul li a .icon {
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    line-height: normal;
}

.header-expert .top-menu ul li a .link {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    line-height: 13px;
}

@media (max-width: 1023px) {
    .header-expert .top-menu ul li a {
        padding-bottom: 0;
        height: 75px;
    }
}

.mfp-wrap.popup-box-inline {
    margin: 0 0 0 -360px;
    padding: 0;
    top: 0;
    left: 50%;
    overflow: visible;
    overflow-y: scroll;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    width: 720px;
    height: 100%;
    background: #ffffff;
}

@media (max-width: 720px) {
    .mfp-wrap.popup-box-inline {
        margin: 0;
        padding: 0;
        position: fixed!important;
        left: 0;
        top: 0!important;
        width: 100%;
        height: 100%!important;
    }
}

.revs-carousel {
    position: relative;
    text-align: center;
}

.revs-carousel .owl-carousel .owl-stage-outer {
    padding-bottom: 30px;
}

.revs-carousel .owl-dots {
    position: absolute;
    left: 0;
    bottom: 2px;
    width: 100%;
    height: 8px;
    text-align: center;
}

.revs-carousel .owl-dots .owl-dot {
    margin: 0 3px;
    display: inline-block;
    vertical-align: top;
    width: 10px;
    height: 10px;
    border: 1px solid #78cc6d;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}

.revs-carousel .owl-dot.active {
    background: #78cc6d;
}

.revs-item .text {
    margin-bottom: 30px;
    padding: 0 30px;
    position: relative;
    font-weight: 300;
    font-style: italic;
    opacity: 0.65;
}

.revs-item .text:before,
.revs-item .text:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    width: 14px;
    height: 14px;
    background-size: contain;
    opacity: 0.65;
}

.revs-item .text:after {
    left: auto;
    top: auto;
    right: 3px;
    bottom: 3px;
    transform: scale(-1);
    -webkit-transform: scale(-1);
}

.revs-item .user .img {
    margin: 0 auto;
    display: block;
    width: 60px;
}

.revs-item .user .img img {
    width: 100%;
    height: auto;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}

.revs-item .info .name {
    margin: 6px 0 0 0;
    font-size: 17px;
    font-weight: 500;
    color: #323232;
}

.revs-item .info .company {
    font-size: 14px;
    color: #999999;
    font-weight: 400;
}


/*
	New Styles
*/


/* Cards */

.card-inner {
    transition: visibility 1s linear, opacity 0s linear;
    -moz-transition: visibility 1s linear, opacity 0s linear;
    -webkit-transition: visibility 1s linear, opacity 0s linear;
    -o-transition: visibility 1s linear, opacity 0s linear;
}

.content.works .filter-menu .f_btn input {
    cursor: pointer;
}

.revs-item {
    position: relative;
    text-align: center;
    max-width: 648px;
    width: 100%;
}


/* Skills */

.skills-list ul {
    clear: both;
}

.skills-list.circles ul {
    margin: 0 -20px;
    text-align: center;
}

.skills-list.list ul {
    padding-top: 10px;
}

.skills-list.circles ul li {
    padding-bottom: 40px;
    display: inline-block;
    vertical-align: top;
    width: 47%;
}

.skills-list.list ul li {
    padding-top: 10px;
    padding-bottom: 10px;
}

.content.skills .row .col.col-d-12 .skills-list.circles ul li,
.content.skills .row .col.col-t-12 .skills-list.circles ul li {
    width: 24%;
}

.skills-list.circles ul li .name {
    position: absolute;
    top: 130px;
    left: 0;
    width: 100%;
    text-align: center;
}

.skills-list.list ul li .name {
    padding-left: 30px;
    margin-bottom: 0;
    position: relative;
}

.skills-list.list ul li .name:before {
    content: "\f121";
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    font-family: "Ionicons";
    color: #78cc6d;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.skills-list.dotted ul li .progress {
    background: transparent!important;
}

.skills-list.list ul li .progress {
    display: none;
}

.skills-list.dotted ul li .progress .percentage {
    position: relative;
    overflow: hidden;
    top: -2px;
    height: 14px;
    background: transparent!important;
    z-index: 2;
}

.skills-list.dotted ul li .progress .dg,
.skills-list.dotted ul li .progress .da {
    position: absolute;
    left: 0;
    top: -2px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 14px;
}

.skills-list.dotted ul li .progress .da {
    top: 0;
}

.skills-list.dotted ul li .progress .dg span,
.skills-list.dotted ul li .progress .da span {
    display: block;
    width: 14px;
    height: 14px;
    background: #eee;
    -webkit-border-radius: 14px;
    border-radius: 14px;
}

.skills-list.dotted ul li .progress .da span {
    background: #78cc6d;
}

.skills-list.circles .progress.p51 .slice,
.skills-list.circles .progress.p52 .slice,
.skills-list.circles .progress.p53 .slice,
.skills-list.circles .progress.p54 .slice,
.skills-list.circles .progress.p55 .slice,
.skills-list.circles .progress.p56 .slice,
.skills-list.circles .progress.p57 .slice,
.skills-list.circles .progress.p58 .slice,
.skills-list.circles .progress.p59 .slice,
.skills-list.circles .progress.p60 .slice,
.skills-list.circles .progress.p61 .slice,
.skills-list.circles .progress.p62 .slice,
.skills-list.circles .progress.p63 .slice,
.skills-list.circles .progress.p64 .slice,
.skills-list.circles .progress.p65 .slice,
.skills-list.circles .progress.p66 .slice,
.skills-list.circles .progress.p67 .slice,
.skills-list.circles .progress.p68 .slice,
.skills-list.circles .progress.p69 .slice,
.skills-list.circles .progress.p70 .slice,
.skills-list.circles .progress.p71 .slice,
.skills-list.circles .progress.p72 .slice,
.skills-list.circles .progress.p73 .slice,
.skills-list.circles .progress.p74 .slice,
.skills-list.circles .progress.p75 .slice,
.skills-list.circles .progress.p76 .slice,
.skills-list.circles .progress.p77 .slice,
.skills-list.circles .progress.p78 .slice,
.skills-list.circles .progress.p79 .slice,
.skills-list.circles .progress.p80 .slice,
.skills-list.circles .progress.p81 .slice,
.skills-list.circles .progress.p82 .slice,
.skills-list.circles .progress.p83 .slice,
.skills-list.circles .progress.p84 .slice,
.skills-list.circles .progress.p85 .slice,
.skills-list.circles .progress.p86 .slice,
.skills-list.circles .progress.p87 .slice,
.skills-list.circles .progress.p88 .slice,
.skills-list.circles .progress.p89 .slice,
.skills-list.circles .progress.p90 .slice,
.skills-list.circles .progress.p91 .slice,
.skills-list.circles .progress.p92 .slice,
.skills-list.circles .progress.p93 .slice,
.skills-list.circles .progress.p94 .slice,
.skills-list.circles .progress.p95 .slice,
.skills-list.circles .progress.p96 .slice,
.skills-list.circles .progress.p97 .slice,
.skills-list.circles .progress.p98 .slice,
.skills-list.circles .progress.p99 .slice,
.skills-list.circles .progress.p100 .slice {
    clip: rect(auto, auto, auto, auto);
}

.skills-list.circles .progress .bar,
.skills-list.circles .progress.p51 .fill,
.skills-list.circles .progress.p52 .fill,
.skills-list.circles .progress.p53 .fill,
.skills-list.circles .progress.p54 .fill,
.skills-list.circles .progress.p55 .fill,
.skills-list.circles .progress.p56 .fill,
.skills-list.circles .progress.p57 .fill,
.skills-list.circles .progress.p58 .fill,
.skills-list.circles .progress.p59 .fill,
.skills-list.circles .progress.p60 .fill,
.skills-list.circles .progress.p61 .fill,
.skills-list.circles .progress.p62 .fill,
.skills-list.circles .progress.p63 .fill,
.skills-list.circles .progress.p64 .fill,
.skills-list.circles .progress.p65 .fill,
.skills-list.circles .progress.p66 .fill,
.skills-list.circles .progress.p67 .fill,
.skills-list.circles .progress.p68 .fill,
.skills-list.circles .progress.p69 .fill,
.skills-list.circles .progress.p70 .fill,
.skills-list.circles .progress.p71 .fill,
.skills-list.circles .progress.p72 .fill,
.skills-list.circles .progress.p73 .fill,
.skills-list.circles .progress.p74 .fill,
.skills-list.circles .progress.p75 .fill,
.skills-list.circles .progress.p76 .fill,
.skills-list.circles .progress.p77 .fill,
.skills-list.circles .progress.p78 .fill,
.skills-list.circles .progress.p79 .fill,
.skills-list.circles .progress.p80 .fill,
.skills-list.circles .progress.p81 .fill,
.skills-list.circles .progress.p82 .fill,
.skills-list.circles .progress.p83 .fill,
.skills-list.circles .progress.p84 .fill,
.skills-list.circles .progress.p85 .fill,
.skills-list.circles .progress.p86 .fill,
.skills-list.circles .progress.p87 .fill,
.skills-list.circles .progress.p88 .fill,
.skills-list.circles .progress.p89 .fill,
.skills-list.circles .progress.p90 .fill,
.skills-list.circles .progress.p91 .fill,
.skills-list.circles .progress.p92 .fill,
.skills-list.circles .progress.p93 .fill,
.skills-list.circles .progress.p94 .fill,
.skills-list.circles .progress.p95 .fill,
.skills-list.circles .progress.p96 .fill,
.skills-list.circles .progress.p97 .fill,
.skills-list.circles .progress.p98 .fill,
.skills-list.circles .progress.p99 .fill,
.skills-list.circles .progress.p100 .fill {
    position: absolute;
    width: 0.82em;
    height: 0.82em;
    clip: rect(0em, 0.5em, 1em, 0em);
    border: 0.09em solid #78cc6d;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.skills-list.circles .progress.p51 .bar:after,
.skills-list.circles .progress.p51 .fill,
.skills-list.circles .progress.p52 .bar:after,
.skills-list.circles .progress.p52 .fill,
.skills-list.circles .progress.p53 .bar:after,
.skills-list.circles .progress.p53 .fill,
.skills-list.circles .progress.p54 .bar:after,
.skills-list.circles .progress.p54 .fill,
.skills-list.circles .progress.p55 .bar:after,
.skills-list.circles .progress.p55 .fill,
.skills-list.circles .progress.p56 .bar:after,
.skills-list.circles .progress.p56 .fill,
.skills-list.circles .progress.p57 .bar:after,
.skills-list.circles .progress.p57 .fill,
.skills-list.circles .progress.p58 .bar:after,
.skills-list.circles .progress.p58 .fill,
.skills-list.circles .progress.p59 .bar:after,
.skills-list.circles .progress.p59 .fill,
.skills-list.circles .progress.p60 .bar:after,
.skills-list.circles .progress.p60 .fill,
.skills-list.circles .progress.p61 .bar:after,
.skills-list.circles .progress.p61 .fill,
.skills-list.circles .progress.p62 .bar:after,
.skills-list.circles .progress.p62 .fill,
.skills-list.circles .progress.p63 .bar:after,
.skills-list.circles .progress.p63 .fill,
.skills-list.circles .progress.p64 .bar:after,
.skills-list.circles .progress.p64 .fill,
.skills-list.circles .progress.p65 .bar:after,
.skills-list.circles .progress.p65 .fill,
.skills-list.circles .progress.p66 .bar:after,
.skills-list.circles .progress.p66 .fill,
.skills-list.circles .progress.p67 .bar:after,
.skills-list.circles .progress.p67 .fill,
.skills-list.circles .progress.p68 .bar:after,
.skills-list.circles .progress.p68 .fill,
.skills-list.circles .progress.p69 .bar:after,
.skills-list.circles .progress.p69 .fill,
.skills-list.circles .progress.p70 .bar:after,
.skills-list.circles .progress.p70 .fill,
.skills-list.circles .progress.p71 .bar:after,
.skills-list.circles .progress.p71 .fill,
.skills-list.circles .progress.p72 .bar:after,
.skills-list.circles .progress.p72 .fill,
.skills-list.circles .progress.p73 .bar:after,
.skills-list.circles .progress.p73 .fill,
.skills-list.circles .progress.p74 .bar:after,
.skills-list.circles .progress.p74 .fill,
.skills-list.circles .progress.p75 .bar:after,
.skills-list.circles .progress.p75 .fill,
.skills-list.circles .progress.p76 .bar:after,
.skills-list.circles .progress.p76 .fill,
.skills-list.circles .progress.p77 .bar:after,
.skills-list.circles .progress.p77 .fill,
.skills-list.circles .progress.p78 .bar:after,
.skills-list.circles .progress.p78 .fill,
.skills-list.circles .progress.p79 .bar:after,
.skills-list.circles .progress.p79 .fill,
.skills-list.circles .progress.p80 .bar:after,
.skills-list.circles .progress.p80 .fill,
.skills-list.circles .progress.p81 .bar:after,
.skills-list.circles .progress.p81 .fill,
.skills-list.circles .progress.p82 .bar:after,
.skills-list.circles .progress.p82 .fill,
.skills-list.circles .progress.p83 .bar:after,
.skills-list.circles .progress.p83 .fill,
.skills-list.circles .progress.p84 .bar:after,
.skills-list.circles .progress.p84 .fill,
.skills-list.circles .progress.p85 .bar:after,
.skills-list.circles .progress.p85 .fill,
.skills-list.circles .progress.p86 .bar:after,
.skills-list.circles .progress.p86 .fill,
.skills-list.circles .progress.p87 .bar:after,
.skills-list.circles .progress.p87 .fill,
.skills-list.circles .progress.p88 .bar:after,
.skills-list.circles .progress.p88 .fill,
.skills-list.circles .progress.p89 .bar:after,
.skills-list.circles .progress.p89 .fill,
.skills-list.circles .progress.p90 .bar:after,
.skills-list.circles .progress.p90 .fill,
.skills-list.circles .progress.p91 .bar:after,
.skills-list.circles .progress.p91 .fill,
.skills-list.circles .progress.p92 .bar:after,
.skills-list.circles .progress.p92 .fill,
.skills-list.circles .progress.p93 .bar:after,
.skills-list.circles .progress.p93 .fill,
.skills-list.circles .progress.p94 .bar:after,
.skills-list.circles .progress.p94 .fill,
.skills-list.circles .progress.p95 .bar:after,
.skills-list.circles .progress.p95 .fill,
.skills-list.circles .progress.p96 .bar:after,
.skills-list.circles .progress.p96 .fill,
.skills-list.circles .progress.p97 .bar:after,
.skills-list.circles .progress.p97 .fill,
.skills-list.circles .progress.p98 .bar:after,
.skills-list.circles .progress.p98 .fill,
.skills-list.circles .progress.p99 .bar:after,
.skills-list.circles .progress.p99 .fill,
.skills-list.circles .progress.p100 .bar:after,
.skills-list.circles .progress.p100 .fill {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.skills-list.circles .progress {
    margin: 0 auto;
    position: relative;
    font-size: 90px;
    width: 90px;
    height: 90px;
    border-radius: 90px;
    background: #eeeeee;
}

.skills-list.circles .progress *,
.skills-list.circles .progress *:before,
.skills-list.circles .progress *:after {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.skills-list.circles .progress span {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 90px;
    font-size: 17px;
    color: #323232;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    z-index: 2;
}

.skills-list.circles .progress:after {
    position: absolute;
    top: 0.09em;
    left: 0.09em;
    display: block;
    content: " ";
    border-radius: 50%;
    background-color: #ffffff;
    width: 0.82em;
    height: 0.82em;
}

.skills-list.circles ul li .progress .percentage {
    display: none;
}

.skills-list.circles .progress .slice {
    position: absolute;
    width: 1em;
    height: 1em;
    clip: rect(0em, 1em, 1em, 0.5em);
}

.skills-list.circles .progress.p1 .bar {
    -moz-transform: rotate(3.6deg);
    -ms-transform: rotate(3.6deg);
    -webkit-transform: rotate(3.6deg);
    transform: rotate(3.6deg);
}

.skills-list.circles .progress.p2 .bar {
    -moz-transform: rotate(7.2deg);
    -ms-transform: rotate(7.2deg);
    -webkit-transform: rotate(7.2deg);
    transform: rotate(7.2deg);
}

.skills-list.circles .progress.p3 .bar {
    -moz-transform: rotate(10.8deg);
    -ms-transform: rotate(10.8deg);
    -webkit-transform: rotate(10.8deg);
    transform: rotate(10.8deg);
}

.skills-list.circles .progress.p4 .bar {
    -moz-transform: rotate(14.4deg);
    -ms-transform: rotate(14.4deg);
    -webkit-transform: rotate(14.4deg);
    transform: rotate(14.4deg);
}

.skills-list.circles .progress.p5 .bar {
    -moz-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
}

.skills-list.circles .progress.p6 .bar {
    -moz-transform: rotate(21.6deg);
    -ms-transform: rotate(21.6deg);
    -webkit-transform: rotate(21.6deg);
    transform: rotate(21.6deg);
}

.skills-list.circles .progress.p7 .bar {
    -moz-transform: rotate(25.2deg);
    -ms-transform: rotate(25.2deg);
    -webkit-transform: rotate(25.2deg);
    transform: rotate(25.2deg);
}

.skills-list.circles .progress.p8 .bar {
    -moz-transform: rotate(28.8deg);
    -ms-transform: rotate(28.8deg);
    -webkit-transform: rotate(28.8deg);
    transform: rotate(28.8deg);
}

.skills-list.circles .progress.p9 .bar {
    -moz-transform: rotate(32.4deg);
    -ms-transform: rotate(32.4deg);
    -webkit-transform: rotate(32.4deg);
    transform: rotate(32.4deg);
}

.skills-list.circles .progress.p10 .bar {
    -moz-transform: rotate(36deg);
    -ms-transform: rotate(36deg);
    -webkit-transform: rotate(36deg);
    transform: rotate(36deg);
}

.skills-list.circles .progress.p11 .bar {
    -moz-transform: rotate(39.6deg);
    -ms-transform: rotate(39.6deg);
    -webkit-transform: rotate(39.6deg);
    transform: rotate(39.6deg);
}

.skills-list.circles .progress.p12 .bar {
    -moz-transform: rotate(43.2deg);
    -ms-transform: rotate(43.2deg);
    -webkit-transform: rotate(43.2deg);
    transform: rotate(43.2deg);
}

.skills-list.circles .progress.p13 .bar {
    -moz-transform: rotate(46.8deg);
    -ms-transform: rotate(46.8deg);
    -webkit-transform: rotate(46.8deg);
    transform: rotate(46.8deg);
}

.skills-list.circles .progress.p14 .bar {
    -moz-transform: rotate(50.4deg);
    -ms-transform: rotate(50.4deg);
    -webkit-transform: rotate(50.4deg);
    transform: rotate(50.4deg);
}

.skills-list.circles .progress.p15 .bar {
    -moz-transform: rotate(54deg);
    -ms-transform: rotate(54deg);
    -webkit-transform: rotate(54deg);
    transform: rotate(54deg);
}

.skills-list.circles .progress.p16 .bar {
    -moz-transform: rotate(57.6deg);
    -ms-transform: rotate(57.6deg);
    -webkit-transform: rotate(57.6deg);
    transform: rotate(57.6deg);
}

.skills-list.circles .progress.p17 .bar {
    -moz-transform: rotate(61.2deg);
    -ms-transform: rotate(61.2deg);
    -webkit-transform: rotate(61.2deg);
    transform: rotate(61.2deg);
}

.skills-list.circles .progress.p18 .bar {
    -moz-transform: rotate(64.8deg);
    -ms-transform: rotate(64.8deg);
    -webkit-transform: rotate(64.8deg);
    transform: rotate(64.8deg);
}

.skills-list.circles .progress.p19 .bar {
    -moz-transform: rotate(68.4deg);
    -ms-transform: rotate(68.4deg);
    -webkit-transform: rotate(68.4deg);
    transform: rotate(68.4deg);
}

.skills-list.circles .progress.p20 .bar {
    -moz-transform: rotate(72deg);
    -ms-transform: rotate(72deg);
    -webkit-transform: rotate(72deg);
    transform: rotate(72deg);
}

.skills-list.circles .progress.p21 .bar {
    -moz-transform: rotate(75.6deg);
    -ms-transform: rotate(75.6deg);
    -webkit-transform: rotate(75.6deg);
    transform: rotate(75.6deg);
}

.skills-list.circles .progress.p22 .bar {
    -moz-transform: rotate(79.2deg);
    -ms-transform: rotate(79.2deg);
    -webkit-transform: rotate(79.2deg);
    transform: rotate(79.2deg);
}

.skills-list.circles .progress.p23 .bar {
    -moz-transform: rotate(82.8deg);
    -ms-transform: rotate(82.8deg);
    -webkit-transform: rotate(82.8deg);
    transform: rotate(82.8deg);
}

.skills-list.circles .progress.p24 .bar {
    -moz-transform: rotate(86.4deg);
    -ms-transform: rotate(86.4deg);
    -webkit-transform: rotate(86.4deg);
    transform: rotate(86.4deg);
}

.skills-list.circles .progress.p25 .bar {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.skills-list.circles .progress.p26 .bar {
    -moz-transform: rotate(93.6deg);
    -ms-transform: rotate(93.6deg);
    -webkit-transform: rotate(93.6deg);
    transform: rotate(93.6deg);
}

.skills-list.circles .progress.p27 .bar {
    -moz-transform: rotate(97.2deg);
    -ms-transform: rotate(97.2deg);
    -webkit-transform: rotate(97.2deg);
    transform: rotate(97.2deg);
}

.skills-list.circles .progress.p28 .bar {
    -moz-transform: rotate(100.8deg);
    -ms-transform: rotate(100.8deg);
    -webkit-transform: rotate(100.8deg);
    transform: rotate(100.8deg);
}

.skills-list.circles .progress.p29 .bar {
    -moz-transform: rotate(104.4deg);
    -ms-transform: rotate(104.4deg);
    -webkit-transform: rotate(104.4deg);
    transform: rotate(104.4deg);
}

.skills-list.circles .progress.p30 .bar {
    -moz-transform: rotate(108deg);
    -ms-transform: rotate(108deg);
    -webkit-transform: rotate(108deg);
    transform: rotate(108deg);
}

.skills-list.circles .progress.p31 .bar {
    -moz-transform: rotate(111.6deg);
    -ms-transform: rotate(111.6deg);
    -webkit-transform: rotate(111.6deg);
    transform: rotate(111.6deg);
}

.skills-list.circles .progress.p32 .bar {
    -moz-transform: rotate(115.2deg);
    -ms-transform: rotate(115.2deg);
    -webkit-transform: rotate(115.2deg);
    transform: rotate(115.2deg);
}

.skills-list.circles .progress.p33 .bar {
    -moz-transform: rotate(118.8deg);
    -ms-transform: rotate(118.8deg);
    -webkit-transform: rotate(118.8deg);
    transform: rotate(118.8deg);
}

.skills-list.circles .progress.p34 .bar {
    -moz-transform: rotate(122.4deg);
    -ms-transform: rotate(122.4deg);
    -webkit-transform: rotate(122.4deg);
    transform: rotate(122.4deg);
}

.skills-list.circles .progress.p35 .bar {
    -moz-transform: rotate(126deg);
    -ms-transform: rotate(126deg);
    -webkit-transform: rotate(126deg);
    transform: rotate(126deg);
}

.skills-list.circles .progress.p36 .bar {
    -moz-transform: rotate(129.6deg);
    -ms-transform: rotate(129.6deg);
    -webkit-transform: rotate(129.6deg);
    transform: rotate(129.6deg);
}

.skills-list.circles .progress.p37 .bar {
    -moz-transform: rotate(133.2deg);
    -ms-transform: rotate(133.2deg);
    -webkit-transform: rotate(133.2deg);
    transform: rotate(133.2deg);
}

.skills-list.circles .progress.p38 .bar {
    -moz-transform: rotate(136.8deg);
    -ms-transform: rotate(136.8deg);
    -webkit-transform: rotate(136.8deg);
    transform: rotate(136.8deg);
}

.skills-list.circles .progress.p39 .bar {
    -moz-transform: rotate(140.4deg);
    -ms-transform: rotate(140.4deg);
    -webkit-transform: rotate(140.4deg);
    transform: rotate(140.4deg);
}

.skills-list.circles .progress.p40 .bar {
    -moz-transform: rotate(144deg);
    -ms-transform: rotate(144deg);
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
}

.skills-list.circles .progress.p41 .bar {
    -moz-transform: rotate(147.6deg);
    -ms-transform: rotate(147.6deg);
    -webkit-transform: rotate(147.6deg);
    transform: rotate(147.6deg);
}

.skills-list.circles .progress.p42 .bar {
    -moz-transform: rotate(151.2deg);
    -ms-transform: rotate(151.2deg);
    -webkit-transform: rotate(151.2deg);
    transform: rotate(151.2deg);
}

.skills-list.circles .progress.p43 .bar {
    -moz-transform: rotate(154.8deg);
    -ms-transform: rotate(154.8deg);
    -webkit-transform: rotate(154.8deg);
    transform: rotate(154.8deg);
}

.skills-list.circles .progress.p44 .bar {
    -moz-transform: rotate(158.4deg);
    -ms-transform: rotate(158.4deg);
    -webkit-transform: rotate(158.4deg);
    transform: rotate(158.4deg);
}

.skills-list.circles .progress.p45 .bar {
    -moz-transform: rotate(162deg);
    -ms-transform: rotate(162deg);
    -webkit-transform: rotate(162deg);
    transform: rotate(162deg);
}

.skills-list.circles .progress.p46 .bar {
    -moz-transform: rotate(165.6deg);
    -ms-transform: rotate(165.6deg);
    -webkit-transform: rotate(165.6deg);
    transform: rotate(165.6deg);
}

.skills-list.circles .progress.p47 .bar {
    -moz-transform: rotate(169.2deg);
    -ms-transform: rotate(169.2deg);
    -webkit-transform: rotate(169.2deg);
    transform: rotate(169.2deg);
}

.skills-list.circles .progress.p48 .bar {
    -moz-transform: rotate(172.8deg);
    -ms-transform: rotate(172.8deg);
    -webkit-transform: rotate(172.8deg);
    transform: rotate(172.8deg);
}

.skills-list.circles .progress.p49 .bar {
    -moz-transform: rotate(176.4deg);
    -ms-transform: rotate(176.4deg);
    -webkit-transform: rotate(176.4deg);
    transform: rotate(176.4deg);
}

.skills-list.circles .progress.p50 .bar {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.skills-list.circles .progress.p51 .bar {
    -moz-transform: rotate(183.6deg);
    -ms-transform: rotate(183.6deg);
    -webkit-transform: rotate(183.6deg);
    transform: rotate(183.6deg);
}

.skills-list.circles .progress.p52 .bar {
    -moz-transform: rotate(187.2deg);
    -ms-transform: rotate(187.2deg);
    -webkit-transform: rotate(187.2deg);
    transform: rotate(187.2deg);
}

.skills-list.circles .progress.p53 .bar {
    -moz-transform: rotate(190.8deg);
    -ms-transform: rotate(190.8deg);
    -webkit-transform: rotate(190.8deg);
    transform: rotate(190.8deg);
}

.skills-list.circles .progress.p54 .bar {
    -moz-transform: rotate(194.4deg);
    -ms-transform: rotate(194.4deg);
    -webkit-transform: rotate(194.4deg);
    transform: rotate(194.4deg);
}

.skills-list.circles .progress.p55 .bar {
    -moz-transform: rotate(198deg);
    -ms-transform: rotate(198deg);
    -webkit-transform: rotate(198deg);
    transform: rotate(198deg);
}

.skills-list.circles .progress.p56 .bar {
    -moz-transform: rotate(201.6deg);
    -ms-transform: rotate(201.6deg);
    -webkit-transform: rotate(201.6deg);
    transform: rotate(201.6deg);
}

.skills-list.circles .progress.p57 .bar {
    -moz-transform: rotate(205.2deg);
    -ms-transform: rotate(205.2deg);
    -webkit-transform: rotate(205.2deg);
    transform: rotate(205.2deg);
}

.skills-list.circles .progress.p58 .bar {
    -moz-transform: rotate(208.8deg);
    -ms-transform: rotate(208.8deg);
    -webkit-transform: rotate(208.8deg);
    transform: rotate(208.8deg);
}

.skills-list.circles .progress.p59 .bar {
    -moz-transform: rotate(212.4deg);
    -ms-transform: rotate(212.4deg);
    -webkit-transform: rotate(212.4deg);
    transform: rotate(212.4deg);
}

.skills-list.circles .progress.p60 .bar {
    -moz-transform: rotate(216deg);
    -ms-transform: rotate(216deg);
    -webkit-transform: rotate(216deg);
    transform: rotate(216deg);
}

.skills-list.circles .progress.p61 .bar {
    -moz-transform: rotate(219.6deg);
    -ms-transform: rotate(219.6deg);
    -webkit-transform: rotate(219.6deg);
    transform: rotate(219.6deg);
}

.skills-list.circles .progress.p62 .bar {
    -moz-transform: rotate(223.2deg);
    -ms-transform: rotate(223.2deg);
    -webkit-transform: rotate(223.2deg);
    transform: rotate(223.2deg);
}

.skills-list.circles .progress.p63 .bar {
    -moz-transform: rotate(226.8deg);
    -ms-transform: rotate(226.8deg);
    -webkit-transform: rotate(226.8deg);
    transform: rotate(226.8deg);
}

.skills-list.circles .progress.p64 .bar {
    -moz-transform: rotate(230.4deg);
    -ms-transform: rotate(230.4deg);
    -webkit-transform: rotate(230.4deg);
    transform: rotate(230.4deg);
}

.skills-list.circles .progress.p65 .bar {
    -moz-transform: rotate(234deg);
    -ms-transform: rotate(234deg);
    -webkit-transform: rotate(234deg);
    transform: rotate(234deg);
}

.skills-list.circles .progress.p66 .bar {
    -moz-transform: rotate(237.6deg);
    -ms-transform: rotate(237.6deg);
    -webkit-transform: rotate(237.6deg);
    transform: rotate(237.6deg);
}

.skills-list.circles .progress.p67 .bar {
    -moz-transform: rotate(241.2deg);
    -ms-transform: rotate(241.2deg);
    -webkit-transform: rotate(241.2deg);
    transform: rotate(241.2deg);
}

.skills-list.circles .progress.p68 .bar {
    -moz-transform: rotate(244.8deg);
    -ms-transform: rotate(244.8deg);
    -webkit-transform: rotate(244.8deg);
    transform: rotate(244.8deg);
}

.skills-list.circles .progress.p69 .bar {
    -moz-transform: rotate(248.4deg);
    -ms-transform: rotate(248.4deg);
    -webkit-transform: rotate(248.4deg);
    transform: rotate(248.4deg);
}

.skills-list.circles .progress.p70 .bar {
    -moz-transform: rotate(252deg);
    -ms-transform: rotate(252deg);
    -webkit-transform: rotate(252deg);
    transform: rotate(252deg);
}

.skills-list.circles .progress.p71 .bar {
    -moz-transform: rotate(255.6deg);
    -ms-transform: rotate(255.6deg);
    -webkit-transform: rotate(255.6deg);
    transform: rotate(255.6deg);
}

.skills-list.circles .progress.p72 .bar {
    -moz-transform: rotate(259.2deg);
    -ms-transform: rotate(259.2deg);
    -webkit-transform: rotate(259.2deg);
    transform: rotate(259.2deg);
}

.skills-list.circles .progress.p73 .bar {
    -moz-transform: rotate(262.8deg);
    -ms-transform: rotate(262.8deg);
    -webkit-transform: rotate(262.8deg);
    transform: rotate(262.8deg);
}

.skills-list.circles .progress.p74 .bar {
    -moz-transform: rotate(266.4deg);
    -ms-transform: rotate(266.4deg);
    -webkit-transform: rotate(266.4deg);
    transform: rotate(266.4deg);
}

.skills-list.circles .progress.p75 .bar {
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

.skills-list.circles .progress.p76 .bar {
    -moz-transform: rotate(273.6deg);
    -ms-transform: rotate(273.6deg);
    -webkit-transform: rotate(273.6deg);
    transform: rotate(273.6deg);
}

.skills-list.circles .progress.p77 .bar {
    -moz-transform: rotate(277.2deg);
    -ms-transform: rotate(277.2deg);
    -webkit-transform: rotate(277.2deg);
    transform: rotate(277.2deg);
}

.skills-list.circles .progress.p78 .bar {
    -moz-transform: rotate(280.8deg);
    -ms-transform: rotate(280.8deg);
    -webkit-transform: rotate(280.8deg);
    transform: rotate(280.8deg);
}

.skills-list.circles .progress.p79 .bar {
    -moz-transform: rotate(284.4deg);
    -ms-transform: rotate(284.4deg);
    -webkit-transform: rotate(284.4deg);
    transform: rotate(284.4deg);
}

.skills-list.circles .progress.p80 .bar {
    -moz-transform: rotate(288deg);
    -ms-transform: rotate(288deg);
    -webkit-transform: rotate(288deg);
    transform: rotate(288deg);
}

.skills-list.circles .progress.p81 .bar {
    -moz-transform: rotate(291.6deg);
    -ms-transform: rotate(291.6deg);
    -webkit-transform: rotate(291.6deg);
    transform: rotate(291.6deg);
}

.skills-list.circles .progress.p82 .bar {
    -moz-transform: rotate(295.2deg);
    -ms-transform: rotate(295.2deg);
    -webkit-transform: rotate(295.2deg);
    transform: rotate(295.2deg);
}

.skills-list.circles .progress.p83 .bar {
    -moz-transform: rotate(298.8deg);
    -ms-transform: rotate(298.8deg);
    -webkit-transform: rotate(298.8deg);
    transform: rotate(298.8deg);
}

.skills-list.circles .progress.p84 .bar {
    -moz-transform: rotate(302.4deg);
    -ms-transform: rotate(302.4deg);
    -webkit-transform: rotate(302.4deg);
    transform: rotate(302.4deg);
}

.skills-list.circles .progress.p85 .bar {
    -moz-transform: rotate(306deg);
    -ms-transform: rotate(306deg);
    -webkit-transform: rotate(306deg);
    transform: rotate(306deg);
}

.skills-list.circles .progress.p86 .bar {
    -moz-transform: rotate(309.6deg);
    -ms-transform: rotate(309.6deg);
    -webkit-transform: rotate(309.6deg);
    transform: rotate(309.6deg);
}

.skills-list.circles .progress.p87 .bar {
    -moz-transform: rotate(313.2deg);
    -ms-transform: rotate(313.2deg);
    -webkit-transform: rotate(313.2deg);
    transform: rotate(313.2deg);
}

.skills-list.circles .progress.p88 .bar {
    -moz-transform: rotate(316.8deg);
    -ms-transform: rotate(316.8deg);
    -webkit-transform: rotate(316.8deg);
    transform: rotate(316.8deg);
}

.skills-list.circles .progress.p89 .bar {
    -moz-transform: rotate(320.4deg);
    -ms-transform: rotate(320.4deg);
    -webkit-transform: rotate(320.4deg);
    transform: rotate(320.4deg);
}

.skills-list.circles .progress.p90 .bar {
    -moz-transform: rotate(324deg);
    -ms-transform: rotate(324deg);
    -webkit-transform: rotate(324deg);
    transform: rotate(324deg);
}

.skills-list.circles .progress.p91 .bar {
    -moz-transform: rotate(327.6deg);
    -ms-transform: rotate(327.6deg);
    -webkit-transform: rotate(327.6deg);
    transform: rotate(327.6deg);
}

.skills-list.circles .progress.p92 .bar {
    -moz-transform: rotate(331.2deg);
    -ms-transform: rotate(331.2deg);
    -webkit-transform: rotate(331.2deg);
    transform: rotate(331.2deg);
}

.skills-list.circles .progress.p93 .bar {
    -moz-transform: rotate(334.8deg);
    -ms-transform: rotate(334.8deg);
    -webkit-transform: rotate(334.8deg);
    transform: rotate(334.8deg);
}

.skills-list.circles .progress.p94 .bar {
    -moz-transform: rotate(338.4deg);
    -ms-transform: rotate(338.4deg);
    -webkit-transform: rotate(338.4deg);
    transform: rotate(338.4deg);
}

.skills-list.circles .progress.p95 .bar {
    -moz-transform: rotate(342deg);
    -ms-transform: rotate(342deg);
    -webkit-transform: rotate(342deg);
    transform: rotate(342deg);
}

.skills-list.circles .progress.p96 .bar {
    -moz-transform: rotate(345.6deg);
    -ms-transform: rotate(345.6deg);
    -webkit-transform: rotate(345.6deg);
    transform: rotate(345.6deg);
}

.skills-list.circles .progress.p97 .bar {
    -moz-transform: rotate(349.2deg);
    -ms-transform: rotate(349.2deg);
    -webkit-transform: rotate(349.2deg);
    transform: rotate(349.2deg);
}

.skills-list.circles .progress.p98 .bar {
    -moz-transform: rotate(352.8deg);
    -ms-transform: rotate(352.8deg);
    -webkit-transform: rotate(352.8deg);
    transform: rotate(352.8deg);
}

.skills-list.circles .progress.p99 .bar {
    -moz-transform: rotate(356.4deg);
    -ms-transform: rotate(356.4deg);
    -webkit-transform: rotate(356.4deg);
    transform: rotate(356.4deg);
}

.skills-list.circles .progress.p100 .bar {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}


/*
	Sidebar Widgets
*/

.s_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    z-index: 106;
    opacity: 0.6;
    display: none;
}

.content-sidebar {
    position: fixed;
    top: 0;
    left: -438px;
    width: 338px;
    height: 100%;
    background: #fff;
    z-index: 107;
    transition: all 0.6s ease 0s;
    -moz-transition: all 0.6s ease 0s;
    -webkit-transition: all 0.6s ease 0s;
    -o-transition: all 0.6s ease 0s;
}

.content-sidebar .close {
    position: absolute;
    top: 0;
    right: -68px;
    width: 68px;
    height: 68px;
    background: #eeeeee;
    cursor: pointer;
    border-radius: 0 0 4px 0;
    -moz-border-radius: 0 0 4px 0;
    -webkit-border-radius: 0 0 4px 0;
    -khtml-border-radius: 0 0 4px 0;
    z-index: 10;
}

.content-sidebar .close:before,
.content-sidebar .close:after {
    content: '';
    margin: -2px 0 0 -10px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 2px;
    background: #363636;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.content-sidebar .close:after {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.content-sidebar .close:hover:before,
.content-sidebar .close:hover:after {
    background: #999;
}

.content-sidebar .sidebar-wrap {
    position: relative;
}

.content-sidebar.active {
    left: 0;
}

.content-sidebar .widget-area {
    position: relative;
}

.content-sidebar .profile {
    padding-top: 150px;
    padding-bottom: 0;
    height: auto;
}

.content-sidebar .profile.no-photo {
    padding-top: 270px;
}

.content-sidebar .profile .slide {
    height: 250px;
}

.content-sidebar .main-menu {
    padding: 25px 0;
    display: none;
}

.content-sidebar .main-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.content-sidebar .main-menu ul li {
    position: relative;
}

.content-sidebar .main-menu ul li:after,
.content-sidebar .main-menu ul li:first-child::before {
    content: '';
    position: absolute;
    left: -25px;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: -moz-radial-gradient(left, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(left, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at left, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
}

.content-sidebar .main-menu ul li:first-child:before {
    bottom: auto;
    top: 0;
}

.content-sidebar .main-menu ul li a {
    position: relative;
    padding: 17px 25px;
    display: block;
    font-weight: 500;
    color: #363636;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.6s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.content-sidebar .main-menu ul li .children_toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: 65px;
    height: 55px;
    z-index: 2;
    cursor: pointer;
}

.content-sidebar .main-menu ul li .icon {
    margin-right: 10px;
    font-size: 16px;
}

.content-sidebar .main-menu ul li.page_item_has_children>a:before,
.content-sidebar .main-menu ul li.page_item_has_children>a:after {
    margin-top: -1px;
    content: '';
    position: absolute;
    right: 25px;
    top: 50%;
    width: 9px;
    height: 2px;
    background: #666;
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

.content-sidebar .main-menu ul li.page_item_has_children>a:after {
    right: 30px;
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.content-sidebar .main-menu ul li.current_page_item>a,
.content-sidebar .main-menu ul li:hover>a,
.content-sidebar .main-menu ul li.current_page_parent>a,
.content-sidebar .main-menu ul li.current_page_ancestor>a {
    text-decoration: none;
    color: #78cc6d;
}

.content-sidebar .main-menu ul li.page_item_has_children.current_page_item>a:before,
.content-sidebar .main-menu ul li.page_item_has_children.current_page_item>a:after,
.content-sidebar .main-menu ul li.page_item_has_children:hover>a:before,
.content-sidebar .main-menu ul li.page_item_has_children:hover>a:after,
.content-sidebar .main-menu ul li.page_item_has_children.current_page_parent>a:before,
.content-sidebar .main-menu ul li.page_item_has_children.current_page_parent>a:after,
.content-sidebar .main-menu ul li.page_item_has_children.current_page_ancestor>a:before,
.content-sidebar .main-menu ul li.page_item_has_children.current_page_ancestor>a:after {
    background: #78cc6d;
}

.content-sidebar .main-menu ul li ul {
    padding: 10px 0 10px 25px;
    background: #f6f6f6;
    display: none;
}

.content-sidebar .main-menu ul li ul li a {
    position: relative;
    padding: 6px 25px;
    display: block;
    font-weight: 400;
    color: #363636;
    font-size: 14px;
}

.content-sidebar .main-menu ul li ul li:before,
.content-sidebar .main-menu ul li ul li:after {
    display: none;
}

.content-sidebar .widget {
    padding: 25px;
}

.content-sidebar .widget tfoot {
    display: none;
}

.content-sidebar .widget th {
    color: #000;
    padding: 5px;
    text-align: center;
}

.content-sidebar .widget td {
    padding: 5px;
    text-align: center;
}

.content-sidebar .widget label {
    padding-bottom: 0;
}

.content-sidebar .widget ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.content-sidebar .widget ul ul {
    margin-top: 5px;
    margin-left: 0;
    margin-bottom: 5px;
    padding: 5px 10px;
    font-size: 14px;
}

.content-sidebar .widget ul ul ul {
    margin-top: 5px;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
}

.content-sidebar .widget ul li {
    position: relative;
    padding: 10px 0;
    margin: 0;
    color: #999;
    border-top: 1px solid #eee;
}

.content-sidebar .widget ul li:last-child {
    border-bottom: 1px solid #eee;
}

.content-sidebar .widget ul li a {
    padding-bottom: 0;
    color: #363636;
}

.content-sidebar .widget ul li a:hover {
    color: #78cc6d;
}

.content-sidebar .widget ul li li {
    position: relative;
    overflow: hidden;
    margin: 5px 0 0 0;
    padding: 0;
    padding-left: 15px;
    border: none;
}

.content-sidebar .widget ul li li:before {
    margin: 0;
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 4px;
    height: 4px;
    background: #78cc6d;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
}

.content-sidebar .widget ul li li li {
    padding-top: 0;
    padding-bottom: 0;
}

.content-sidebar .widget ul li li li li {
    padding-left: 10px;
}

.content-sidebar .widget ul li li li {
    padding-top: 0;
    padding-bottom: 0;
}

.content-sidebar .widget ul li li li:before {
    top: 8px;
}

.content-sidebar .widget ul li li:last-child {
    border-bottom: none;
}

.content-sidebar .widget.widget_recent_comments a,
.content-sidebar .widget.widget_recent_entries a {
    padding-top: 0;
    font-weight: 400;
    display: block!important;
}

.content-sidebar .widget ul li .post-date {
    margin: 0;
    display: inline-block;
    padding: 0;
    height: 20px;
    line-height: 18px;
    font-weight: 400;
    font-size: 14px;
    color: #999;
}

.content-sidebar .widget img {
    max-width: 100%;
    height: auto;
}

.content-sidebar .widget .rss-date,
.content-sidebar .widget cite {
    margin: 5px 0 10px 0;
    padding: 0;
    display: block;
    height: 20px;
    line-height: 18px;
    font-weight: 400;
    font-size: 14px;
    color: #999;
}

.content-sidebar .widget cite {
    margin: 10px 0 0 0;
}

.content-sidebar .widget .rssSummary {
    color: #363636;
}

.content-sidebar span.screen-reader-text {
    display: none;
}

.content-sidebar span.screen-reader-text span {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    -khtml-box-shadow: none;
}

.search-form input.search-field {
    border: solid 1px #ddd;
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 0 50px 0 15px;
    box-shadow: none;
    font-weight: 400;
    -webkit-appearance: textfield;
}

.search-form input.search-submit {
    margin-top: -50px;
    margin-right: 0;
    padding: 0;
    float: right;
    width: 50px;
    height: 50px;
    /* background: url(../images/android-search.png) no-repeat center center; */
    background-size: 18px 18px;
    font-size: 0;
    border: none;
}

.search-form input.search-submit:hover {
    /* background: url(../images/android-search.png) no-repeat center center; */
    background-size: 18px 18px;
}

.content-sidebar h2.widget-title {
    margin: 0 0 15px 0;
    padding: 0 0 5px 0;
    position: relative;
    font-size: 18px;
    color: #323232;
    line-height: 21px;
    font-weight: 600;
}

.content-sidebar h2.widget-title .first-word {
    color: #78cc6d;
}

.content-sidebar h2.widget-title::first-letter,
.content-sidebar h2.widget-title .first-letter::first-letter {
    color: #78cc6d;
}

.content-sidebar h2.widget-title:before {
    content: '';
    position: absolute;
    left: -12px;
    top: 0;
    width: 30px;
    height: 30px;
    background: -moz-linear-gradient(-45deg, rgba(46, 202, 127, 0.15) 0%, rgba(46, 202, 127, 0.01) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(46, 202, 127, 0.15) 0%, rgba(46, 202, 127, 0.01) 100%);
    background: linear-gradient(135deg, rgba(46, 202, 127, 0.15) 0%, rgba(46, 202, 127, 0.01) 100%);
    z-index: -1;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    -khtml-border-radius: 30px;
}

.content-sidebar .recentcomments {
    color: #999;
}

.content-sidebar .recentcomments a {
    padding-top: 6px;
    display: block!important;
}

.content-sidebar .recentcomments a.url {
    margin: 0;
    padding: 0;
    display: inline-block;
    height: 20px;
    line-height: 18px;
    font-weight: 400;
    font-size: 14px;
    color: #999;
}

.content-sidebar .recentcomments .comment-author-link {
    margin: 0;
    padding: 0;
    display: inline-block;
    height: 20px;
    line-height: 18px;
    font-weight: 400;
    font-size: 14px;
    color: #999;
}

.content-sidebar .tagcloud a {
    margin: 4px 2px;
    display: inline-block;
    vertical-align: bottom;
    padding: 0 5px;
    font-weight: 400;
    font-size: 13px;
    color: #78cc6d;
    border: 1px solid #78cc6d;
}

.content-sidebar .screen-reader-text {
    display: none;
}

.content-sidebar .widget select {
    width: 100%;
    height: 34px;
}

.content-sidebar table {
    margin: 0;
}

.content-sidebar .widget.widget_media_image {
    max-width: 100%;
}

.logged-in .content-sidebar {
    top: 28px;
}

.logged-in .content-sidebar .widget:last-child {
    padding-bottom: 50px;
}

p.no-comments {
    margin: 25px 0;
}

@media (max-width: 1199px) {
    .content-sidebar .sidebar-wrap {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
    .content-sidebar .main-menu {
        padding: 25px;
        display: block;
    }
    .content-sidebar .main-menu ul li:after,
    .content-sidebar .main-menu ul li:first-child::before {
        left: 0;
    }
}

@media (max-width: 782px) {
    .logged-in .content-sidebar {
        top: 42px;
    }
}

@media (max-width: 680px) {
    html.sidebar-open,
    body.sidebar-open {
        height: 100vh;
        overflow: hidden;
    }
    .content-sidebar {
        left: -100%;
        width: 100%;
    }
    .content-sidebar.active {
        left: 0;
    }
    .content-sidebar .sidebar-wrap {
        padding-top: 68px;
    }
    .content-sidebar .close {
        right: 0;
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        -khtml-border-radius: 0;
        width: 100%;
    }
    .post-comments .post-comment .desc .name a {
        display: block;
    }
    .post-comments .post-comment .desc .comment-time,
    .post-comments .post-comment .desc .comment-date {
        margin: 0;
    }
    .post-comments .post-comment .desc span.comment-reply {
        position: relative;
        top: 4px;
        display: block;
    }
    .comment-respond .title_inner small {
        position: relative;
        top: 5px;
    }
    .header-expert .menu-btn {
        margin-bottom: 0;
    }
}

.post-box.single-post-text .search-form input.search-submit {
    margin-top: -60px;
}

.post-box.single-post-text .search-form span.screen-reader-text {
    margin: 0 0 10px 0;
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #323232;
}

.typed-cursor {
    display: none!important;
}