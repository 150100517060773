.de-search-form {

    position: relative;
      background: #FFF;
      padding-left: 11px;
      font-size: 14px;
      border: 1px solid #b8c7c8;
  }
  
  
  
  
  
      .de-search-form .de-scontrol {
  
      position: relative;
  
      height: 60px;
  
      padding: 20px 0;
  
  }
  .de-search-form .de-scontrol input {
      width: 100%;
      height: 20px;
      border: none;
      padding: 0 15px;
      color: #404b50;
  }
  
  
  
  input {
  
      outline: 0;
  
  }
  
  
  
  
  
  
  
  button, input, select, textarea {
  
      font-family: inherit;
  
      font-size: inherit;
  
      line-height: inherit;
  
  }
  
  button, select {
  
      text-transform: none;
  
  }
  
  button, input, optgroup, select, textarea {
  
      margin: 0;
  
      font: inherit;
  
      color: inherit;
  
  }
  
  
  
  .chosen-container {
  
      position: relative;
  
      display: inline-block;
  
      vertical-align: middle;
  
      font-size: 13px;
  
      -webkit-user-select: none;
  
      -moz-user-select: none;
  
      -ms-user-select: none;
  
      user-select: none;
  
  }
  
  
  
  
  
  
  
  .de-search-form .chosen-container-single .chosen-single {
  
      border: none;
  
      border-left: 1px solid #e4e4e4;
  
      height: 20px;
  
      line-height: 20px;
  
      color: #404b50;
  
  }
  
  .chosen-container-single .chosen-single {
  
      overflow: hidden;
  
      padding: 0 0 0 15px;
  
      height: 40px;
  
      border: 1px solid rgba(189,195,199,0.4);
  
      border-radius: 0;
  
      background: #FFF;
  
      background-clip: padding-box;
  
      color: #5f6f81;
  
      text-decoration: none;
  
      white-space: nowrap;
  
      line-height: 24px;
  
      box-shadow: none;
  
  }
  
  .chosen-container-single .chosen-single {
  
      position: relative;
  
      display: block;
  
      }
  
  
  
  
  
      .de-search-form select.de-chosen-single {
  
      opacity: 0;
  
  }
  
  button, input, select, textarea {
  
      font-family: inherit;
  
      font-size: inherit;
  
      line-height: inherit;
  
  }
  
  button, select {
  
      text-transform: none;
  
  }
  
  button, input, optgroup, select, textarea {
  
      margin: 0;
  
      font: inherit;
  
      color: inherit;
  
  }
  
  
  
  
  
  
  
  
  
  .de-search-form .de-search-btn {
  
      width: 150px;
  
      height: 40px;
  
      border-radius: 50px;
  
      border: none;
  
      background-color: #D72C2D;
  
      box-shadow: 0 3px 15px 0 rgba(215, 44, 45,0.4);
  
      outline: none;
  
      position: absolute;
  
      top: 10px;
  
      right: 11px;
  
      text-transform: uppercase;
  
  }
  
  .btn-primary {
  
      color: #fff;
  
      background-color: #337ab7;
  
      border-color: #2e6da4;
  
  }
  .title-place {
    font-family: 'Open Sans', sans-serif;
    padding: 7px 10px;
    margin: 0 0 7px;
    color: #3c3c3b;
    font-size: 16px;
    height: 50px;
    line-height: 1.3;
    text-transform: uppercase;
    }
    .list-places .place-wrapper .place-detail-wrapper span.address-place {
    color: #7b7b7c !important;
    }
    .annonce.annonce{
    /*border: 1px solid #ed2829;*/
    border-radius: 0px;
    color: #ed2829;
    padding: 0 5px;
    background-color: transparent;
    position: absolute;
    left: 10px;
    top: 60px;
    z-index: 9999;
}
  
#list-places-wrapper{
    margin-top:0;    
    padding-top: 20px;
    background-color: white;
}

.loader-entreprise-title p {
    background-color: #eee;
    height: 40px;
}
.loader-entreprise-image p {
    background-color: #eee;
    height: 140px;
    margin: 10px;
}
.loader-entreprise-tel-add-prod {
    background-color: #eee;
    width: 60%;
    height: 16px;
    display: block;
    float: right;
}
.infowindow .img-place img, .list-places .place-wrapper .img-place img {
    height: 150px;
    width: 100%;
    object-fit: contain;
}
.contint-pack i {
    display: block;
    width: 55px;
    height: 55px;
    position: absolute;
    top: 0px;
    background-size: contain;
}
.contint-pack {
    width: 55px;
    height: 55px;
    position: absolute;
    top: -10px;
    right: -10px;
}
.contint-pack.platinium i{
    background-image: url(./business.png);
}
.contint-pack.premium i{
    background-image: url(./pro.png);
}