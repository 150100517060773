/**
*	Ryan - Resume/CV/vCard Template (HTML)
*	Version: 2.5
*	Author: beshleyua
*	Author URL: http://themeforest.net/user/beshleyua
*	Copyright © Cvio by beshleyua. All Rights Reserved.
**/


/*
	New Skin
*/




/*
	Typography
*/

.page-expert h1,
.page-expert h2,
.page-expert h3,
.page-expert h4,
.page-expert h5,
.page-expert h6 {
    margin: 0 0 25px 0;
    font-size: 30px;
    color: #323232;
    line-height: 1.4em;
    font-weight: 600;
}

.h-title {
    margin-bottom: 5px;
}

.page-expert h2 {
    font-size: 24px;
}

.page-expert h3 {
    font-size: 22px;
}

.page-expert h4 {
    font-size: 20px;
}

.page-expert h5 {
    font-size: 16px;
}

.page-expert h6 {
    font-size: 14px;
}

.page-expert p {
    font-size: 16px;
    line-height: 1.8em;
    padding: 0;
    margin: 0 0 25px 0;
}

strong {
    font-weight: 600;
}

@media (max-width: 680px) {
    .page-expert h1,
    .page-expert h2,
    .page-expert h3,
    .page-expert h4,
    .page-expert h5,
    .page-expert h6 {
        font-size: 26px;
    }
    .page-expert h2 {
        font-size: 23px;
    }
    .page-expert h3 {
        font-size: 20px;
    }
    .page-expert h4 {
        font-size: 18px;
    }
    .page-expert h5 {
        font-size: 16px;
    }
    .page-expert h6 {
        font-size: 14px;
    }
}

/*::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 6px;
    border-radius: 6px;
}*/

.card-inner .card-wrap,
.content-sidebar .sidebar-wrap,
.profile .main-menu-fixed .main-menu {
    height: auto;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}


/*
	Container
*/

.page {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-expert .container {
    margin: auto;
    margin-top: 0px;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    max-width: 1268px;
    max-height: 674px;
}

.page-expert .container.opened {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
}

@media (max-height: 700px) {
    .page-expert .container {
        margin-top: 0vh;
        margin-bottom: 3vh;
        height: 100vh;
    }
}

@media (max-height: 740px) {
    .page-expert .container {
        margin-top: 0px !important;
        margin-bottom: 6vh;
        height: 100vh;
    }
}

@media (max-width: 1300px) {
    .page-expert .container {
        margin: 5vh 3vw;
        height: 100vh;
    }
}

@media (max-width: 1199px) {
    body {
        overflow: visible;
    }
    .page-expert .container {
        margin: 0 auto;
        padding: 0 15px 0 100px;
        position: relative;
        top: 20px;
        left: 0;
        width: auto;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 700px;
        max-height: initial;
        height: auto;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
    }
    .page-expert .container.opened {
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        margin-left: auto;
        margin-right: auto;
    }
    .page {
        height: auto;
        display: block;
    }
}

@media (max-width: 560px) {
    .page-expert .container {
        margin: 0;
        top: 0;
        width: auto;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 60px 0 0 0;
        max-width: 400px;
    }
    .page-expert .container.opened {
        margin-left: auto;
        margin-right: auto;
    }
}


/*
	Columns
*/

.row {
    position: relative;
}

.row.border-line-v:before {
    display: none;
}

.row .col.border-line-v:first-child:before {
    display: none;
}

.row .col {
    padding: 30px;
    font-size: 16px;
}

.row .col .col {
    padding-top: 0;
    padding-bottom: 20px;
}

@media (max-width: 1199px) {
    .row .col.col-t-12 {
        width: 99.996%;
    }
    .row .col.col-t-11 {
        width: 91.663%;
    }
    .row .col.col-t-10 {
        width: 83.333%;
    }
    .row .col.col-t-9 {
        width: 74.997%;
    }
    .row .col.col-t-8 {
        width: 66.664%;
    }
    .row .col.col-t-7 {
        width: 58.331%;
    }
    .row .col.col-t-6 {
        width: 49.998%;
    }
    .row .col.col-t-5 {
        width: 41.665%;
    }
    .row .col.col-t-4 {
        width: 33.332%;
    }
    .row .col.col-t-3 {
        width: 24.999%;
    }
    .row .col.col-t-2 {
        width: 16.666%;
    }
    .row .col.col-t-1 {
        width: 8.333%;
    }
}

@media (max-width: 560px) {
    .row .col.col-m-12 {
        width: 99.996%;
    }
    .row .col.col-m-11 {
        width: 91.663%;
    }
    .row .col.col-m-10 {
        width: 83.333%;
    }
    .row .col.col-m-9 {
        width: 74.997%;
    }
    .row .col.col-m-8 {
        width: 66.664%;
    }
    .row .col.col-m-7 {
        width: 58.331%;
    }
    .row .col.col-m-6 {
        width: 49.998%;
    }
    .row .col.col-m-5 {
        width: 41.665%;
    }
    .row .col.col-m-4 {
        width: 33.332%;
    }
    .row .col.col-m-3 {
        width: 24.999%;
    }
    .row .col.col-m-2 {
        width: 16.666%;
    }
    .row .col.col-m-1 {
        width: 8.333%;
    }
}

.border-line-v:before {
    left: -1px;
    background: -moz-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at top, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
}

.border-line-h:after {
    background: -moz-radial-gradient(center, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(center, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at center, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
}


/*
	Typography
*/

.page-expert p {
    font-size: 16px;
    line-height: 1.8em;
    margin: 0 0 25px 0;
}

strong {
    font-weight: 600;
}


/* links, buttons */

.lnks {
    display: flex;
    flex-direction: row;
}

.lnks:before {
    background: -moz-radial-gradient(center, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(center, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at center, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
}

/*.lnk,
.button,
.page-expert input[type="submit"] {
     display: block;
    float: none;
    width: 100%;
    font-size: 14px;
    color: #323232; 
}*/

.lnk:before,
.button:before {
    background: -moz-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at top, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
}

/*.button:hover,
.button:focus,
.page-expert input[type="submit"] {
     border: none; 
}*/

.button:before {
    display: none;
}

.lnk .arrow:before,
.button .arrow:before,
.lnk .arrow:after,
.button .arrow:after {
    background: #323232;
}

.button {
    padding: 0 30px;
    display: inline-block;
    float: none;
    width: auto;
    height: 50px;
    line-height: 50px;
    background: #eeeeee;
    border-radius: 4px;
}

.button .text {
    padding-left: 14px;
}


/* forms */

/*.page-expert input,
textarea,
button,
.page-expert input[type="submit"] {
     padding: 0 15px;
    font-size: 16px;
    height: 50px;
    color: #323232;
    border: 2px solid #eeeeee; 
}*/

.page-expert input:focus,
textarea:focus {
    color: #323232;
    border: 2px solid #e11a18;
}

.page-expert input.error,
textarea.error {
    border: 2px solid red !important;
}

textarea {
    padding: 15px;
    height: 94px;
}

button {
    color: #323232;
    border-bottom: 1px solid #eeeeee;
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
}

/*.page-expert input[type="submit"] {
     padding: 0 30px;
    border: 1px solid #eeeeee;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
    background: #eee;
    border-radius: 4px;
    -webkit-border-radius: 4px;
} */

.page-expert input[type="checkbox"],
.page-expert input[type="radio"] {
    width: auto;
    height: auto;
    margin-right: 5px;
}

.page-expert input[type="checkbox"] {
    display: none;
}
.page-expert .div-login-expert {
    min-width: 420px;
}
.page-expert .div-login-expert #ihc_login_form .impu-form-line.impu-form-submit {
    position: relative;
    height: 40px;
    width: 100%;
    border: 2px solid #e11a18 !important;
    border-radius: 50px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.page-expert button.btn-connecter{
    width: 100% !important;
    text-align: center;
    color: red;
    font-size: 15px;
}

.page-expert input[type="radio"] {
    -webkit-appearance: radio;
}

.page-expert select {
    width: 100%;
    height: 50px;
}

.page-expert input[type="file"] {
    padding: 0;
    border: none;
    height: auto;
}


/*
	Header
*/

.header-expert {
    margin-right: 8px;
    position: relative;
    left: -15px;
    top: 15px;
    float: left;
    box-shadow: 20px 0px 20px #aaaaaa88;
}

.disable-sidebar .header-expert .menu-btn,
.no-sidebar .header-expert .menu-btn {
    height: 0;
    visibility: hidden;
    opacity: 0;
}

.header-expert .top-menu ul {
    margin: 0;
    padding: 0;
}

.header-expert .top-menu ul li a {
    padding: 13px 3px;
    height: auto;
    color: #323232;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    min-width: 80px;
}

.header-expert .top-menu ul li a:before {
    background: -moz-radial-gradient(right, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(right, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at right, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
}

.header-expert .top-menu ul li a .icon {
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 40px;
    height: 30px;
    font-size: 20px;
    color: #323232;
    line-height: 30px;
}

.header-expert .top-menu ul li ul {
    display: none;
}

@media (max-width: 1199px) {
    .header-expert {
        margin-left: -85px;
        position: fixed;
        left: auto;
        top: 20px;
        width: 72px;
        max-width: 72px;
        height: auto;
        display: block;
        background: #fff;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
    }
    .header-expert .top-menu {
        margin: 0 auto;
        max-width: 100%;
        display: block;
        box-shadow: none;
    }
    .header-expert .top-menu ul {
        display: block;
        width: 100%;
    }
    .header-expert .top-menu ul li {
        display: block;
        width: 100%;
    }
    .header-expert .top-menu ul li:first-child a {
        border-radius: 4px 0 0 4px;
        -moz-border-radius: 4px 0 0 4px;
        -webkit-border-radius: 4px 0 0 4px;
        -khtml-border-radius: 4px 0 0 4px;
    }
    .header-expert .top-menu ul li:last-child a {
        border-radius: 0 0 4px 4px;
        -moz-border-radius: 0 0 4px 4px;
        -webkit-border-radius: 0 0 4px 4px;
        -khtml-border-radius: 0 0 4px 4px;
    }
    .header-expert .top-menu ul li a:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: auto;
        width: 100%;
        height: 1px;
        background: -moz-radial-gradient(right, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
        background: -webkit-radial-gradient(right, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
        background: radial-gradient(ellipse at right, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    }
    .header-expert .top-menu ul li:last-child a:before {
        display: block;
    }
}

@media (max-width: 560px) {
    .header-expert {
        margin-right: 0;
        margin-left: 0;
        padding: 0;
        position: fixed;
        left: 0;
        right: 0;
        top: 70px;
        z-index: 99;
        width: auto;
        height: 64px;
        max-width: 100%;
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        -khtml-border-radius: 0;
    }
    .header.fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    .header-expert .top-menu {
        position: relative;
        margin-top: -4px;
        max-width: 100%;
        display: flex;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    }
    .header-expert .top-menu:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: auto;
        width: 100%;
        height: 1px;
        background: -moz-radial-gradient(left, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
        background: -webkit-radial-gradient(left, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
        background: radial-gradient(ellipse at left, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
        z-index: 2;
    }
    .header-expert .top-menu ul {
        display: flex;
        /* overflow: auto; */
    }
    .header-expert .top-menu ul li {
        min-width: 20%;
    }
    .header-expert .top-menu ul li a {
        padding: 13px 7px;
    }
    .header-expert .top-menu ul li a:before {
        width: 1px;
        height: 100%;
        background: -moz-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
        background: -webkit-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
        background: radial-gradient(ellipse at top, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    }
    .header-expert .top-menu ul li:first-child a:before {
        display: none;
    }
    .header-expert .top-menu ul li:last-child a {
        border-radius: 0 0 4px 0;
        -moz-border-radius: 0 0 4px 0;
        -webkit-border-radius: 0 0 4px 0;
        -khtml-border-radius: 0 0 4px 0;
    }
    .header-expert .top-menu ul {
        background: #fff;
    }
}


/*
	Card - Started
*/

.card-started {
    position: relative;
    float: left;
    width: 480px;
    height: 100%;
}

.card-started .profile {
    padding: 50px;
    position: relative;
    left: 0%;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card-started .profile.no-photo {
    padding-top: 50px;
}

.card-started .profile.no-photo .title {
    margin-top: 10px;
}

.card-started .profile .slide {
    height: 52%;
}

.card-started .profile.no-photo .slide {
    height: 300px;
}

.card-started .profile .slide:before,
.card-started .profile .slide:after {
    left: -25%;
    bottom: -63%;
    width: 100%;
    height: 70%;
}

.card-started .profile .slide:after {
    left: auto;
    right: -25%;
    transform: rotate(-8deg);
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    -o-transform: rotate(-8deg);
}

.card-started .profile .image {
    margin: 80px 0 20px 0;
    width: 146px;
}

.card-started .profile .title,
.content-sidebar .profile .title {
    font-size: 34px;
    color: #323232;
}

.card-started .profile .subtitle,
.card-started .profile .typed-cursor {
    position: relative;
    margin: 0;
    display: inline-block;
    color: #e11a18;
    font-size: 16px;
    line-height: 20px;
    height: 20px;
    font-weight: 400;
}

.card-started .profile .social {
    position: relative;
    margin: 16px 0 0 0;
}


/*
	Cards
*/

.card-inner {
    padding: 0;
    position: absolute;
    overflow: auto;
    left: 560px;
    right: 0;
    top: 15px;
    bottom: 15px;
    width: auto;
    border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
    -khtml-border-radius: 0 4px 4px 0;
    transition: visibility 1s linear, opacity 0s linear;
    -moz-transition: visibility 1s linear, opacity 0s linear;
    -webkit-transition: visibility 1s linear, opacity 0s linear;
    -o-transition: visibility 1s linear, opacity 0s linear;
}

.card-inner:before,
.card-inner:after {
    height: 30px;
}

.card-inner .card-wrap {
    padding: 30px 30px 0 30px;
    position: relative;
}

@media (max-width: 1199px) {
    .card-started {
        position: relative;
        float: none;
        width: auto;
        height: auto;
    }
    .card-inner {
        position: relative;
        margin: 15px 0;
        left: 0;
        top: 0;
        width: auto;
        height: auto;
        opacity: 1;
        visibility: visible;
        border-radius: 4px;
    }
    .card-inner .card-wrap {
        overflow: visible;
        height: auto;
    }
    .header-expert .top-menu ul li:last-child a:before {
        display: block;
    }
    .card-started .profile {
        height: auto;
    }
    .card-started .profile.no-photo .slide {
        height: 300px;
    }
    .card-started .profile.no-photo .title {
        margin-top: 10px;
    }
}

@media (max-width: 560px) {
    .card-inner {
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        -khtml-border-radius: 4px;
    }
    .card-inner .card-wrap {
        padding: 30px;
    }
    .card-inner:last-child {
        margin-bottom: 10px;
    }
    .card-started .profile {
        height: auto;
        min-height: 560px;
    }
    .header-expert .top-menu ul li.contact{
        display: none;
    }
    .page-expert .profile-photo-expert .profile-photo{
        width: 250px;
        height: 250px;
    }
    .page-expert .profile-photo-expert .profile-bg{
        width: 250px;
        height: 250px;
    }
    .page-expert .profile-photo-expert {
        position: relative;
        width: 250px;
        height: 250px;
        margin-top: 50px;
    }
}


/*
	Content
*/

.content {
    margin: 0 0 40px 0;
}

.content:last-child {
    margin-bottom: 30px;
}

.content .title {
    margin: 0 0 0 0;
    padding: 0 0 30px 0;
    font-size: 20px;
    color: #323232;
    line-height: 21px;
    min-height: 51px;
    font-weight: 600;
    z-index: 2;
}

.content .title.no-title:before {
    display: none;
}

.content .title .first-word {
    color: #e11a18;
}

.content .title::first-letter,
.content .title .first-letter::first-letter {
    color: #e11a18;
}

.content .post-comments .title .first-word {
    color: #323232;
}

.content .post-comments .title::first-letter,
.content .post-comments .first-letter::first-letter {
    color: #323232;
}

.content .title:before {
    background: -moz-linear-gradient(-45deg, rgba(46, 202, 127, 0.15) 0%, rgba(46, 202, 127, 0.01) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(46, 202, 127, 0.15) 0%, rgba(46, 202, 127, 0.01) 100%);
    background: linear-gradient(135deg, rgba(46, 202, 127, 0.15) 0%, rgba(46, 202, 127, 0.01) 100%);
}

.content .title:after {
    left: -30px;
    right: 0;
    bottom: 0;
    width: auto;
    background: -moz-radial-gradient(left, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(left, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at left, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
}


/*
	Card - About
*/


/* About */

.text-box p {
    margin-top: 0;
}

.content.about p {
    margin-bottom: 0;
}

.info-list {
    padding-top: 0;
}

.content.contacts .info-list ul {
    margin: -11px -30px 0 -30px;
    font-size: 0;
}

.info-list ul li {
    padding: 11px 0;
    position: relative;
    display: block;
    vertical-align: top;
    width: auto;
    font-size: 16px;
    word-break: break-word;
    text-align: right;
}

.content.contacts .info-list ul li {
    padding: 11px 30px;
    display: inline-block;
    vertical-align: top;
    width: 50%;
}

.info-list ul li:first-child {
    padding-top: 0;
}

.content.contacts .info-list ul li:first-child {
    padding-top: 11px;
}

.info-list ul li:last-child {
    padding-bottom: 0;
}

.content.contacts .info-list ul li:last-child {
    padding-bottom: 11px;
}

.info-list ul li:last-child:before {
    display: none;
}

.content.contacts .info-list ul li:last-child:before {
    display: block;
}

.info-list ul li:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: -moz-radial-gradient(center, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(center, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at center, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
}

.info-list ul li strong {
    float: left;
    margin-right: 8px;
    padding: 1px 8px;
    background: #e11a18;
    font-size: 14px;
    line-height: 20px;
    color: #fefefe;
    font-weight: 400;
    text-transform: none;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -khtml-border-radius: 2px;
}

.info-list ul li strong:after {
    margin-left: 0!important;
}

@media (max-width: 1199px) {
    .content.about .row .col:first-child {
        padding-bottom: 0;
    }
    .info-list ul li,
    .content.contacts .info-list ul li {
        padding: 11px 30px;
        display: block;
        width: 100%;
    }
    .content.about .info-list ul {
        margin: -11px -30px 0 -30px;
    }
}
.text-menu-video-mobile{
    display: none!important;
}
@media (max-width: 560px) {
    .text-menu-video-mobile{
        display: block!important;
    }
    .text-menu-video{
        display: none!important;
    }
    .info-list ul li {
        display: block;
        width: auto;
    }
    .content.about .info-list ul {
        margin: 0px;
    }
    .content.contacts .info-list ul {
        margin: -11px auto 0 auto;
    }
    .content.contacts .info-list ul li {
        padding: 11px 0;
        display: block;
        vertical-align: top;
        width: auto;
    }
    .content.contacts .info-list ul li:first-child {
        padding-top: 11px;
    }
    .content.contacts .info-list ul li:last-child {
        padding-bottom: 11px;
    }
    .content.contacts .info-list ul li:last-child:before {
        display: block;
    }
}


/* Services */

.service-items.border-line-v:after {
    background: -moz-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at top, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
}

.service-items .service-item {
    text-align: center;
}

.service-items .service-item .icon {
    margin: 0 auto;
    color: #fff!important;
    background: #e11a18!important;
}

.service-items .service-item .name {
    margin: 10px 0 5px 0;
    font-size: 17px;
    color: #323232;
    font-weight: 500;
    text-transform: none;
}

.service-items .service-item p {
    margin: 0;
}

@media (max-width: 560px) {
    .service-items .col:last-child:after {
        display: none;
    }
}


/* Pricing */

.pricing-items .pricing-item {
    position: relative;
    padding: 0 0 100px 0;
}

.pricing-items .pricing-item .name {
    color: #323232;
    font-size: 17px;
    text-transform: none;
}

.pricing-items .pricing-item .amount .number {
    color: #323232;
}

.pricing-items .pricing-item .amount .dollar,
.pricing-items .pricing-item .amount .period {
    color: #323232;
    font-size: 16px;
}

.pricing-items .pricing-item .amount .period {
    color: #646464;
}

.pricing-items .pricing-item .feature-list ul li {
    margin: 10px 0;
    font-size: 16px;
    color: #646464;
}

.pricing-items .pricing-item .feature-list ul li:last-child {
    margin-bottom: 0;
    border-bottom: none;
}

.pricing-items .pricing-item .feature-list ul li strong {
    font-size: 13px;
    color: #323232;
}

.pricing-items .pricing-item .lnks .lnk .ion {
    top: -2px;
}


/* Fun Fact */

.fuct-items .fuct-item .name {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #646464;
    text-transform: none;
}


/* Clients */

.client-items .client-item img {
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.client-items .client-item img:hover {
    opacity: 0.8;
}


/*
	Resume
*/

.resume-title,
.skill-title {
    padding: 0 0 30px 0;
}

.resume-title:after,
.skill-title:after {
    left: 0;
}

.resume-title .icon,
.skill-title .icon {
    top: -13px;
}

.resume-title .name,
.skill-title .name {
    line-height: 14px;
    color: #323232;
    font-size: 17px;
}

.resume-title .name:first-child,
.skill-title .name:first-child {
    margin-left: 0;
}


/* Resume */

.resume-items .resume-item {
    padding: 30px 0;
}

.resume-items .resume-item:after {
    left: 0;
}

.resume-items .resume-item:last-child {
    padding-bottom: 0;
}

.resume-items .resume-item:last-child:after {
    display: none;
}

.resume-items .resume-item.active .date {
    border-color: #e11a18;
}

.resume-items .resume-item .date {
    margin: 0 0 11px 0;
    padding: 0 5px;
    font-size: 13px;
    line-height: 18px;
    color: #ccc;
    font-weight: 400;
    text-transform: none;
    border: 1px solid #eeeeee;
}

.resume-items .resume-item .date:before {
    display: none;
}

.resume-items .resume-item .name {
    font-size: 17px;
    color: #323232;
    font-weight: 500;
    text-transform: none;
}

.resume-items .resume-item .company {
    margin: 0 0 11px 0;
    font-size: 14px;
    color: #999999;
    font-weight: 400;
    text-transform: none;
}

.resume-items .resume-item p {
    margin: 0;
}

.skills-list.list ul li .name {
    font-size: 16px;
    line-height: 18px;
}


/*
	Works
*/

.content.works .border-line-h:after {
    display: none;
}

.content.works .filter-menu {
    font-weight: 400;
    z-index: 2;
}

.content.works .filter-menu .f_btn {
    font-size: 14px;
    color: #646464;
}

.content.works .filter-menu .f_btn .page-expert input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.content.works .grid-items.border-line-v:after {
    background: -moz-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at top, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
}

.content.works .grid-items .grid-item.col {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 30px;
}

@media (max-width: 560px) {
    .content.works .filter-menu {
        position: relative;
        top: 5px;
        left: 0;
        right: auto;
    }
    .content.works .filter-menu button {
        margin: 0 15px 0 0;
    }
    .skills-list.circles.col-md-3{
        width: 50%;
    }
}


/* Box Items */

.box-item {
    overflow: visible;
}

.content.blog .box-item {
    text-align: initial;
}

.content.blog .row .col {
    padding-bottom: 0;
}

.box-item .image {
    overflow: hidden;
    z-index: 2;
}

.box-item .image a img {
    transition: all 0.6s ease 0s;
    -moz-transition: all 0.6s ease 0s;
    -webkit-transition: all 0.6s ease 0s;
    -o-transition: all 0.6s ease 0s;
    transform: scale(1);
    -webkit-transform: scale(1);
}

.box-item:hover .image a img {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
}

.box-item .desc {
    padding: 15px 0;
}

.content.works .box-item .desc {
    padding: 15px 0 0 0;
}

.content.blog .box-item .desc {
    padding-bottom: 0;
    text-align: center;
}

.box-item .desc .name {
    padding: 0;
    display: block;
    font-size: 17px;
    color: #323232;
    font-weight: 500;
    text-transform: none;
    word-break: break-word;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.content.blog .box-item .desc .name {
    padding-bottom: 5px;
    font-size: 17px;
    font-weight: 500;
    text-transform: none;
}

.box-item .desc .category {
    margin: 0;
    font-size: 14px;
    color: #999999;
    text-transform: none;
}

.content.blog .box-item .desc {
    padding-bottom: 20px;
}

.content.works .box-item .desc .category {
    font-size: 14px;
}

.box-item:hover .desc .name {
    color: #e11a18;
}

@media (max-width: 560px) {
    .content.works .filter-menu .f_btn {
        margin-left: 0;
        margin-right: 15px;
    }
}


/*
	Contacts
*/

.content.contacts .map {
    margin-bottom: 30px;
    height: 240px;
}

.contact_form .row p {
    display: none;
}

.card-wrap .content.contacts .row .row .col {
    padding-bottom: 30px;
}

.card-wrap .content.contacts .row .row .col.col-d-6:nth-child(1) {
    padding-right: 15px;
}

.card-wrap .content.contacts .row .row .col.col-d-6:nth-child(2) {
    padding-left: 15px;
}

.contact_form .page-expert input[type="checkbox"],
.contact_form .page-expert input[type="radio"] {
    margin: 0 5px 0 0;
    display: inline;
    width: auto;
    height: auto;
}

.checkbox label br {
    display: none;
}


/*
	Popups
*/

.mfp-wrap.popup-box-inline {
    overflow-y: scroll;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
}

.grid-items {
    min-height: 1px;
}

.service-items .service-item .icon .ion,
.service-items .service-item .icon .fab,
.service-items .service-item .icon .fas,
.service-items .service-item .icon .fa {
    display: block;
    line-height: 60px;
}

.service-items .service-item .icon .fab,
.service-items .service-item .icon .fas,
.service-items .service-item .icon .fa {
    font-size: 28px;
}

.pricing-items .pricing-item .icon .ion,
.pricing-items .pricing-item .icon .fab,
.pricing-items .pricing-item .icon .fas,
.pricing-items .pricing-item .icon .fa {
    display: block;
}

.pricing-items .pricing-item .icon .fab,
.pricing-items .pricing-item .icon .fas,
.pricing-items .pricing-item .icon .fa {
    font-size: 28px;
}

.fuct-items .fuct-item .icon .ion,
.fuct-items .fuct-item .icon .fab,
.fuct-items .fuct-item .icon .fas,
.fuct-items .fuct-item .icon .fa {
    display: block;
    line-height: 60px;
}

.fuct-items .fuct-item .icon .fab,
.fuct-items .fuct-item .icon .fas,
.fuct-items .fuct-item .icon .fa {
    font-size: 28px;
}

.resume-title .icon .ion,
.skill-title .icon .ion,
.resume-title .icon .fab,
.skill-title .icon .fab,
.resume-title .icon .fas,
.skill-title .icon .fas,
.resume-title .icon .fa,
.skill-title .icon .fa {
    display: block;
    line-height: 40px;
}

.resume-title .icon .fab,
.skill-title .icon .fab,
.resume-title .icon .fas,
.skill-title .icon .fas,
.resume-title .icon .fa,
.skill-title .icon .fa {
    font-size: 28px;
}

.resume-title .icon .ion,
.skill-title .icon .ion,
.resume-title .icon .fab,
.skill-title .icon .fab,
.resume-title .icon .fas,
.skill-title .icon .fas,
.resume-title .icon .fa,
.skill-title .icon .fa {
    display: block;
    line-height: 40px;
}

.resume-title .icon .fab,
.skill-title .icon .fab,
.resume-title .icon .fas,
.skill-title .icon .fas,
.resume-title .icon .fa,
.skill-title .icon .fa {
    font-size: 28px;
}

.box-item .image .info .fab,
.box-item .image .info .fas,
.box-item .image .info .fa {
    font-size: 28px;
}

.card-started .profile .social a .ion,
.card-started .profile .social a .fab,
.card-started .profile .social a .fas,
.card-started .profile .social a .fa,
.content-sidebar .profile .social a .ion,
.content-sidebar .profile .social a .fab,
.content-sidebar .profile .social a .fas,
.content-sidebar .profile .social a .fa {
    font-size: 16px;
    color: #323232;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.content.blog .border-line-v:after {
    content: '';
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    background: -moz-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at top, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    left: 50%;
}

.content.blog .box-item {
    text-align: initial;
}

.content.blog .box-item .desc {
    padding-bottom: 0;
    text-align: center;
}

.content.blog .box-item .desc .name {
    padding-bottom: 5px;
    font-size: 17px;
    font-weight: 500;
    text-transform: none;
}

.box-item .desc .category {
    margin: 0 0 5px 0;
    font-size: 14px;
    color: #999999;
}

.content.works .box-item .desc .category {
    margin: 5px 0 0 0;
    font-size: 14px;
}

.box-item .desc .text p {
    margin: 0;
}

.box-item .date {
    position: relative;
    left: 0;
    top: 0;
    bottom: auto;
    width: auto;
    height: auto;
    margin: 0 0 11px 0;
    padding: 0 5px;
    display: inline-block;
    line-height: 18px;
    font-weight: 400;
    font-size: 13px;
    color: #e11a18;
    background: transparent;
    border: 1px solid #e11a18;
}

@media (max-width: 680px) {
    .content.works .filter-menu .f_btn {
        margin-left: 0;
        margin-right: 15px;
    }
    .content.blog .border-line-v:after {
        display: none;
    }
}

.contact_form .page-expert input[type="checkbox"],
.contact_form .page-expert input[type="radio"] {
    margin: 0 5px 0 0;
    display: inline;
    width: auto;
    height: auto;
}

.checkbox label br {
    display: none;
}


/*
	Single Post Text
*/

.blog-content:after {
    content: '';
    clear: both;
    position: relative;
    display: block;
}

.single-post-text .blog-detail,
.popup-box .blog-detail {
    margin: 0 0 25px 0;
    font-size: 12px;
    color: #999999;
}

.blog-detail>span:first-child {
    padding-right: 0;
}

.blog-detail span:first-child:before {
    display: none;
}

.blog-detail>span {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    padding-right: 4px;
}

.blog-detail>span:before {
    content: '';
    content: "\02022";
    font-family: serif;
    padding: 0 6px 0 6px;
}

.single-post-text .blog-image,
.blog-content .blog-image {
    margin: 0 0 25px 0;
    font-size: 0;
}

.single-post-text .blog-image img,
.blog-content .blog-image img {
    width: 100%;
}

.single-post-text,
.comment-text {
    word-wrap: break-word;
}

.comment-author {
    margin: 0 0 5px 0;
    display: block;
}

.single-post-text:after,
.blog-content:after {
    content: '';
    position: relative;
    clear: both;
    display: block;
}

.single-post-text img,
.comment-text img,
.blog-content img {
    max-width: 100%;
    height: auto;
}

.single-post-text p a,
.comment-text p a,
.blog-content p a {
    color: #e11a18;
}

.single-post-text p a:hover,
.comment-text p a:hover,
.blog-content p a:hover {
    text-decoration: underline;
}

.single-post-text p:last-child,
.blog-content p:last-child {
    margin-bottom: 0;
}

blockquote {
    margin: 25px 0;
    padding: 0 20px;
    color: #888;
    font-style: italic;
    font-weight: 300;
    border-left: 2px solid #e11a18;
}

blockquote code {
    padding: 2px;
    display: inline;
}

blockquote *:last-child {
    margin-bottom: 0;
}

blockquote p {
    margin-bottom: 10px;
}

blockquote cite {
    font-size: 12px;
}

iframe,
object {
    max-width: 100%;
}

.single-post-text ul,
.comment-text ul,
.blog-content ul {
    list-style: none;
}

.single-post-text ol,
.comment-text ol,
.blog-content ol {
    list-style-position: inside;
}

.single-post-text ul>li,
.single-post-text ol>li,
.comment-text ul>li,
.comment-text ol>li,
.blog-content ul>li,
.blog-content ol>li {
    margin: 8px 0;
    padding: 0 0 0 18px;
    position: relative;
    font-weight: 400;
}

.single-post-text ol>li,
.comment-text ol>li,
.blog-content ol>li {
    margin: 8px 0;
    padding: 0;
    position: relative;
    font-weight: 400;
}

.single-post-text ul>li:before,
.comment-text ul>li:before,
.blog-content ul>li:before {
    margin: 0;
    content: '';
    position: absolute;
    left: 0;
    top: 11px;
    width: 4px;
    height: 4px;
    background: #e11a18;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
}

.single-post-text figure,
.comment-text figure,
.blog-content figure {
    margin: 0 0 30px 0;
    max-width: 100%;
}

.single-post-text figure .wp-caption-text,
.comment-text figure .wp-caption-text,
.gallery-caption,
.bypostauthor {
    font-style: normal;
}

.gallery {
    margin: 30px -10px;
}

.gallery .gallery-item {
    margin: 0;
    padding: 10px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
}

.gallery .gallery-item img {
    width: 100%;
    height: auto;
}

.gallery.gallery-columns-1 .gallery-item {
    width: 100%;
}

.gallery.gallery-columns-2 .gallery-item {
    width: 50%;
}

.gallery.gallery-columns-3 .gallery-item {
    width: 33.333%;
}

.gallery.gallery-columns-4 .gallery-item {
    width: 25%;
}

.gallery.gallery-columns-5 .gallery-item {
    width: 20%;
}

.gallery.gallery-columns-6 .gallery-item {
    width: 16.666%;
}

.gallery.gallery-columns-7 .gallery-item {
    width: 14.28%;
}

.gallery.gallery-columns-8 .gallery-item {
    width: 12.5%;
}

.gallery.gallery-columns-9 .gallery-item {
    width: 11.111%;
}

.post-text-bottom {
    margin: 0;
}

.post-text-bottom>span {
    margin-right: 20px;
    margin-bottom: 15px;
    display: inline-block;
    vertical-align: middle;
}

.post-text-bottom span.cat-links,
.post-text-bottom .byline {
    line-height: 22px;
    color: #999;
}

.post-text-bottom span.cat-links a {
    color: #e11a18;
}

.post-text-bottom span.cat-links a:hover {
    text-decoration: underline;
}

.post-text-bottom .tags-links,
.post-text-bottom .social-share span {
    color: #999;
    margin-bottom: 0;
}

.post-text-bottom .tags-links a,
.post-text-bottom .tags-links span {
    margin: 0 10px 10px 0;
    display: inline-block;
    padding: 0 5px;
    line-height: 18px;
    font-weight: 400;
    font-size: 13px;
    color: #e11a18;
    border: 1px solid #e11a18;
    text-decoration: none;
}

.post-text-bottom .tags-links a:first-child,
.post-text-bottom .tags-links span:first-child {
    margin-left: 10px;
}

.pager,
.page-links,
.nav-links {
    clear: both;
    margin: 0;
    padding: 0;
    text-align: center;
}

.pager,
.pager .nav-links {
    margin: 0;
    padding: 0;
}

.pager .nav-links {
    margin: 30px 0 0 0;
    padding: 13px;
    background: #fafafa;
    border-radius: 4px;
}

.page-expert h2.screen-reader-text {
    display: none;
}

.page-numbers,
.page-links a,
.nav-links a {
    position: relative;
    margin: 0 7px;
    display: inline-block;
    vertical-align: top;
    color: #999;
}

.prev.page-numbers:before,
.next.page-numbers:before {
    margin-top: -6px;
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    display: block;
    width: 11px;
    height: 12px;
    /* background: url(../../images/pag.png) no-repeat center center; */
    background-size: contain;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.next.page-numbers:before {
    left: auto;
    right: 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.prev.page-numbers:hover:before {
    left: -4px;
}

.next.page-numbers:hover:before {
    right: -4px;
}

.page-numbers.current {
    color: #e11a18;
}

.page-links,
.nav-links {
    color: #999;
}

a.prev.page-numbers {
    padding: 0 0 0 15px;
    color: #999;
}

a.next.page-numbers {
    padding: 0 15px 0 0;
    color: #999;
}

a.prev.page-numbers:hover {
    color: #646464;
}

a.next.page-numbers:hover {
    color: #646464;
}

.nav-links {
    padding: 0;
}

.nav-links:after {
    content: '';
    position: relative;
    clear: both;
    display: block;
}

.blog-single .navigation.post-navigation {
    margin: 0;
}

.navigation.post-navigation .nav-links a {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #aca3a3;
    line-height: 20px;
    position: relative;
    margin: 0;
    padding: 5px 10px;
    display: inline-block;
    -webkit-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
}

.navigation.post-navigation .nav-links a:hover {
    color: #646464;
}

.navigation.post-navigation .nav-links .nav-previous a {
    float: left;
    padding-left: 25px;
}

.navigation.post-navigation .nav-links .nav-previous a:before,
.navigation.post-navigation .nav-links .nav-next a:after {
    margin-top: -10px;
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    display: block;
    width: 11px;
    height: 20px;
    /* background: url(../../images/pag.png) no-repeat center center; */
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.navigation.post-navigation .nav-links .nav-previous a:hover:before {
    left: -4px;
}

.navigation.post-navigation .nav-links .nav-next a {
    float: right;
    padding-right: 25px;
}

.navigation.post-navigation .nav-links .nav-next a:after {
    left: auto;
    right: 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.navigation.post-navigation .nav-links .nav-next a:hover:after {
    right: -4px;
}

.post-edit-link {
    color: #999;
    text-decoration: underline;
    word-break: break-word;
}

.post-edit-link:hover {
    text-decoration: none;
}

.post-text-bottom>span.edit-link {
    margin-top: 10px;
    display: block;
}

.post-text-bottom .post-edit-link {
    position: relative;
    padding-left: 25px;
    display: block;
    color: #999;
    text-decoration: none;
    word-break: break-word;
}

.post-text-bottom .post-edit-link:before {
    content: '';
    content: "\f2bf";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 25px;
    line-height: 25px;
    display: inline-block;
    font-family: "Ionicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.post-text-bottom .share-btn {
    position: relative;
    display: inline-block;
    top: 1px;
    margin-left: 7px;
    margin-right: 7px;
    font-size: 16px;
}

.post-text-bottom>span,
.post-text-bottom>.social-share {
    margin-right: 30px;
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: top;
}


/*
	Post Comments
*/

.post-comments {
    margin-top: 0;
}

.post-comments>.title {
    margin-top: 60px;
}

.post-comments .comments-row {
    margin-top: 0;
    margin-bottom: 0;
}

.post-comments .comments-row .col {
    padding-bottom: 0;
}

.post-comments .comments,
.post-comments .children {
    margin: 0;
    padding: 0;
    list-style: none;
}

.post-comments .post-comment {
    padding: 20px 0 0 0;
    margin: 20px 0 0 0;
    position: relative;
    display: block;
    list-style: none;
}

.post-comments .post-comment:before {
    content: '';
    position: absolute;
    left: -30px;
    right: 0;
    top: 0;
    bottom: auto;
    height: 1px;
    background: -moz-radial-gradient(left, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(left, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at left, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
}

.post-comments .post-comment:after {
    content: '';
    position: relative;
    clear: both;
    display: block;
}

.post-comments .post-comment:first-child {
    padding-top: 0;
    margin-top: 0;
}

.post-comments .post-comment:first-child:before {
    display: none;
}

.post-comments .post-comment:last-child {
    margin-bottom: 0;
}

.post-comments .post-comment .image {
    float: left;
    width: 54px;
    height: 54px;
}

.post-comments .post-comment .image img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}

.post-comments .post-comment .desc {
    margin-left: 66px;
    position: relative;
}

.post-comments .post-comment .desc .name {
    margin: 0 0 7px 0;
    position: relative;
    color: #e11a18;
    font-weight: 500;
}

.post-comments .post-comment .desc .name a {
    display: block;
}

.post-comments .post-comment .desc .comment-time,
.post-comments .post-comment .desc .comment-date {
    margin: 0 7px 0 0;
    padding: 0;
    display: inline-block;
    font-size: 14px;
    line-height: 14px;
    color: #999999;
    font-weight: 400;
    text-transform: none;
    border-left: none;
}

.post-comments .post-comment .desc .comment-date {
    margin: 0;
    padding: 0;
    border-left: none;
}

.post-comments .post-comment .desc span.comment-reply {
    position: absolute;
    top: 2px;
    right: 0;
    font-size: 14px;
    color: #323232;
    font-weight: 500;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.post-comments .post-comment .desc span.comment-reply a:before {
    content: "\f21e";
    margin-right: 5px;
    position: relative;
    left: 0;
    display: inline-block;
    font-family: "Ionicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.post-comments .post-comment .desc span.comment-reply a {
    color: #323232;
}

.post-comments .post-comment .desc span.comment-reply a:hover {
    color: #e11a18;
}

.post-comments .post-comment .desc span.comment-reply a:hover:before {
    left: -4px;
}

.post-comments .post-comment .desc .comment-text *:last-child {
    margin: 0;
}

.post-comment.pingback .image {
    display: none;
}

.post-comment.pingback .desc {
    margin-left: 0px;
}

.post-comments ul.children {
    margin: 20px 0 0 0;
    padding: 20px;
    border-top: none;
    background: #f6f6f6;
}

.post-comments ul.children ul.children {
    margin: 20px 0 0 20px;
    padding: 0;
    border-top: none;
    background: #f6f6f6;
}

.comment-respond {
    position: relative;
    margin-top: 0;
}

.logged-in-as {
    margin-bottom: 10px;
}

.logged-in-as a:hover {
    text-decoration: underline;
}

.post-comments .form-comments {
    margin-top: 0;
}

.post-comments .form-comment .title {
    margin: 60px 0 30px 0;
}

.post-comments .form-comments.row .col {
    padding-top: 0;
    padding-bottom: 0;
}

.post-comments .form-comment .group-val {
    margin-bottom: 15px;
}

.post-comments .form-comment .button:before {
    display: none;
}

.comment-respond .title_inner small {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    font-size: 14px;
    display: block;
    white-space: nowrap;
    color: #999;
}

.comment-respond .title_inner small a {
    color: #999;
}

.comment-respond .title_inner small a:hover {
    color: #646464;
}

.comments .comment-respond {
    padding: 30px 0 0 0;
}

.comments .comment-respond .group-val {
    margin-bottom: 15px;
}

.comments .title.comment-reply-title {
    margin-bottom: 10px;
}

.comments .title.comment-reply-title:before,
.comments .title.comment-reply-title:after {
    display: none;
}

.post-comments .title {
    padding: 0 0 10px 0;
    font-size: 17px;
    line-height: 26px;
    font-weight: 500;
}

.post-comments .title:before {
    display: none;
}

@media (max-width: 1160px) {
    .post-comments .post-comment .image {
        width: 60px;
        height: 60px;
    }
    .post-comments .post-comment .desc {
        margin-left: 80px;
    }
    .post-comments .post-comment.pingback .desc {
        margin-left: 0;
    }
    .post-comments ul.children ul.children ul.children ul.children {
        margin: 20px 0 0 10px;
    }
}

@media (max-width: 680px) {
    .section .content .comment-respond .title.comment-reply-title {
        text-align: left;
    }
    .post-comments ul.children {
        padding: 15px 15px 15px 15px;
    }
    .post-comments ul.children ul.children {
        margin: 15px 0 0 10px;
    }
    .post-comments ul.children ul.children ul.children {
        margin: 15px 0 0 5px;
    }
    .post-comments ul.children ul.children ul.children ul.children {
        margin: 15px 0 0 5px;
    }
    .post-comments .post-comment .image {
        width: 40px;
        height: 40px;
    }
    .post-comments .post-comment .desc {
        margin-left: 55px;
    }
    .post-comments .post-comment.pingback .desc {
        margin-left: 0;
    }
    .gallery .gallery-item {
        width: 100%;
    }
    .gallery.gallery-columns-1 .gallery-item {
        width: 100%;
    }
    .gallery.gallery-columns-2 .gallery-item {
        width: 100%;
    }
    .gallery.gallery-columns-3 .gallery-item {
        width: 100%;
    }
    .gallery.gallery-columns-4 .gallery-item {
        width: 100%;
    }
    .gallery.gallery-columns-5 .gallery-item {
        width: 100%;
    }
    .gallery.gallery-columns-6 .gallery-item {
        width: 100%;
    }
    .gallery.gallery-columns-7 .gallery-item {
        width: 100%;
    }
    .gallery.gallery-columns-8 .gallery-item {
        width: 100%;
    }
    .gallery.gallery-columns-9 .gallery-item {
        width: 100%;
    }
    th {
        border: 2px solid #fff;
        padding: 5px;
    }
    td {
        border: 2px solid #fff;
        padding: 5px;
    }
    .card-wrap .content.contacts .row .row .col.col-d-6:nth-child(1) {
        padding-right: 30px;
    }
    .card-wrap .content.contacts .row .row .col.col-d-6:nth-child(2) {
        padding-left: 30px;
    }
    .card-inner:before,
    .card-inner:after {
        height: 0;
    }
}

.profile .main-menu-fixed {
    padding: 75px 25px 100px 25px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-out 1s;
    -moz-transition: all 0.3s ease-out 1s;
    -webkit-transition: all 0.3s ease-out 1s;
    -o-transition: all 0.3s ease-out 1s;
    visibility: hidden;
    opacity: 0;
}

.profile .main-menu-fixed:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.99;
}

.profile .menu-main-menu-container,
.profile .main-menu .menu {
    position: relative;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile .slimScrollDiv {
    width: 100%!important;
}

.profile .main-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.profile .main-menu ul li a {
    position: relative;
    padding: 5px 25px;
    font-size: 28px;
    font-weight: 500;
    color: #323232;
    display: inline-block;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.profile .main-menu ul li a .icon {
    position: absolute;
    left: 0;
    top: 0;
    line-height: 45px;
    opacity: 0;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.profile .main-menu .menu-main-menu-container:hover ul li a,
.profile .main-menu .menu-main-menu-container:hover ul li a .icon {
    color: #aaa;
}

.profile .main-menu ul li.current-menu-item a,
.profile .main-menu .menu-main-menu-container ul li a:hover,
.profile .main-menu ul li.current-menu-item a .icon,
.profile .main-menu .menu-main-menu-container ul li a:hover .icon {
    color: #323232;
}

.profile .main-menu ul li.current-menu-item a {
    color: #e11a18;
}

.card-started .profile.default-menu-open .profile-content {
    transition: all 0.02s ease 0.2s;
    -moz-transition: all 0.5s ease 0.2s;
    -webkit-transition: all 0.5s ease 0.2s;
    -o-transition: all 0.5s ease 0.2s;
}

.card-started .profile.default-menu-open .main-menu-fixed {
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
}

.profile .main-menu .menu>ul>li,
.profile .main-menu ul.menu>li {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    -webkit-transform: translate3d(0, -20px, 0);
    transition: all 1s ease 0.3s;
    -moz-transition: all 1s ease 0.3s;
    -webkit-transition: all 1s ease 0.3s;
    -o-transition: all 1s ease 0.3s;
}

.profile.default-menu-open .main-menu .menu>ul>li,
.profile.default-menu-open .main-menu ul.menu>li {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}

.profile .main-menu ul li .children_toggle {
    margin-left: -28px;
    position: relative;
    display: inline-block;
    top: 4px;
    right: -8px;
    width: 28px;
    height: 28px;
    z-index: 2;
    cursor: pointer;
}

.profile .main-menu ul li ul li .children_toggle {
    height: 18px;
}

.profile .main-menu ul li.page_item_has_children>a:before,
.profile .main-menu ul li.page_item_has_children>a:after {
    margin-top: -1px;
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 9px;
    height: 2px;
    background: #666;
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

.profile .main-menu ul li.page_item_has_children>a:after {
    right: 5px;
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.profile .main-menu ul li.current_page_item>a,
.profile .main-menu ul li:hover>a,
.profile .main-menu ul li.current_page_parent>a,
.profile .main-menu ul li.current_page_ancestor>a {
    text-decoration: none;
    color: #e11a18;
}

.profile .main-menu ul li.page_item_has_children.current_page_item>a:before,
.profile .main-menu ul li.page_item_has_children.current_page_item>a:after,
.profile .main-menu ul li.page_item_has_children:hover>a:before,
.profile .main-menu ul li.page_item_has_children:hover>a:after,
.profile .main-menu ul li.page_item_has_children.current_page_parent>a:before,
.profile .main-menu ul li.page_item_has_children.current_page_parent>a:after,
.profile .main-menu ul li.page_item_has_children.current_page_ancestor>a:before,
.profile .main-menu ul li.page_item_has_children.current_page_ancestor>a:after {
    background: #e11a18;
}

.profile .main-menu ul li ul {
    padding: 10px 25px;
    display: none;
}

.profile .main-menu ul li ul li a {
    position: relative;
    padding: 6px 25px;
    font-weight: 500;
    color: #646464;
    font-size: 18px;
}

.profile .main-menu ul li ul li ul li a {
    font-size: 15px;
}

.profile .main-menu ul li ul li:before,
.profile .main-menu ul li ul li:after {
    display: none;
}

.lnk .ion,
.button .ion,
.lnk .arrow,
.button .arrow,
.lnk .fa,
.button .fa {
    margin: 0 8px;
    position: relative;
    top: -1px;
    left: 0;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.lnk.lnk-view-menu .ion,
.button.lnk-view-menu .ion,
.lnk.lnk-view-menu .arrow,
.button.lnk-view-menu .arrow,
.lnk.lnk-view-menu .fa,
.button.lnk-view-menu .fa {
    font-size: 20px;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

.default-menu-open .lnk.lnk-view-menu .ion,
.default-menu-open .button.lnk-view-menu .ion,
.default-menu-open .lnk.lnk-view-menu .arrow,
.default-menu-open .button.lnk-view-menu .arrow,
.default-menu-open .lnk.lnk-view-menu .fa,
.default-menu-open .button.lnk-view-menu .fa {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}

.disable-sidebar.no-sticky-menu .card-inner,
.no-sidebar .no-sticky-menu .card-inner {
    left: 480px;
}

@media (max-width: 1120px) {
    .disable-sidebar.no-sticky-menu .card-inner,
    .no-sidebar .no-sticky-menu .card-inner {
        left: 0;
    }
}

@media (max-width: 680px) {
    .page-expert .container {
        padding-top: 0px;
    }
}