.page-conference .wpnaw-news-image {
    height: 250px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border: solid 1px #eee;
}
.page-conference .wpnaw-news-grid-content{
    background-color: transparent !important;
    background:none;
    padding-left: 0px;
}
.page-conference h2.wpnaw-news-title {
    margin: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.page-conference .wpnaw-news-title a {
    color: #555;
    text-decoration: none !important;
    border-bottom: 0 !important;
}

.page-formation .wpnaw-news-date ,.page-formation .page-detai-webinaires .wpnaw-news-date {
    background-color: #00000055;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 95px;
    right: 5px;
    left: unset;
}
.page-detai-webinaires .block-last-items .col-md-4{
    width: 100% !important;
    max-width: 100% !important;
    zoom: 100%;
}
.page-detai-webinaires .block-last-items .date-resrt-deff,
.page-detai-webinaires .block-last-items .date-resrt-expirer,
.page-detai-webinaires .block-last-items .date-resrt-encours{
    top: 10%;
    bottom: unset;
    left: 10%;
    right: 10%;
}
.page-detai-webinaires .block-last-items .date-resrt-expirer,.page-detai-webinaires .block-last-items .date-resrt-encours{
    left: unset;
}
.wpnaw-news-date-d,.wpnaw-news-date-m,.wpnaw-news-date-t{
    font-weight: 800;
    /* color: #3c3c3b; */
    color: #fff;
    display: block;
    width: 100%;
    text-align: center;
}
.wpnaw-news-date-d {
    font-size: 35px;
}
.wpnaw-news-date-m{
    font-size: 20px;
    text-transform: uppercase;
}
.wpnaw-news-date-t{
    font-weight: 500;
    font-size: 18px;  
}
.date-resrt {
    display: flex;
    justify-content: center;
    align-items: center;
}
.date-resrt .date-resrt-jhms,.date-resrt .date-resrt-expirer,.date-resrt .date-resrt-encours {
    border: solid 1px #aaa;
    border-radius: 3px;
    min-width: 40px;
    height: 40px;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    margin-bottom: 5px;
    padding: 5px;
}
.date-resrt .date-resrt-jhms {
    border: unset;
    color: #000;
    font-weight: 700;
    margin: 0px;
    border-right: solid 2px red;
    text-transform: uppercase;
    border-radius: 0px;
}
.date-resrt .date-resrt-jhms:last-child{
    border: unset;
}
.date-resrt .date-resrt-expirer{
    position: absolute;
    border-color: red;
    color: #fff;
    top: 5px;
    right: 15px;
    background-color: #ff0000;
    font-size: 20px;
    font-weight: 600;
    border-radius: 50px;
    padding: 5px 10px 5px 10px;
}
.date-resrt .date-resrt-encours{
    color: white;
    background-color: green;
    position: absolute;
    border-color: green;
    top: 5px;
    right: 15px;
    opacity: 0.7;
}
.date-resrt .date-resrt-p {
    height: 40px;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    margin-bottom: 5px;
    padding: 0px;
}
.wpnaw-news-date .blok-duree{
    display: grid;
    justify-content: start;
    align-items: center;
}
.page-formation .date-resrt-deff {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    background-color: #ffffffbb;
    padding: 5px;
    border-radius: 50px;
}
.page-detai-webinaires .date-resrt-deff{
    top: unset;
    bottom: 10px;
    right: 10px;
}
.block-last-items .wpnaw-news-grid-content{
    display: none !important;
}
.btn-participer{
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px !important;
    margin-bottom: 10px !important;
    cursor: pointer;
}